<div class="modal-header">

  <div *ngIf="selectedTicket != null && selectedTicket.id != null" class="flex flex-row justify-start gap-3 items-center w-full">
  <div  (mouseenter)="showLinkButton()" (click)="openTicketDetailsPage()" class="ticket-header flex flex-row justify-start gap-3 items-center ease-in-out duration-300 cursor-pointer badge bg-success rounded-xl text-xl font-bold" style="background-color: #fbd9d4; color: #ed6754;">
    <span >Ticket Number - #{{selectedTicket.ticketNumber}}</span>
  </div>

    <button (click)="copyTicketLink()" tooltip="Copy Link to Ticket" type="button" class="btn btn-dlight h-8 w-8 rounded-full p-0" style="background-color: #fbd9d4;border:0">
      <svg width="24" height="24" fill="#ed6754" viewBox="0 0 0.48 0.48" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M.132.09h.093a.1.1 0 0 1 .102.102v.006A.1.1 0 0 1 .225.3H.21V.27h.015A.07.07 0 0 0 .296.198V.192A.07.07 0 0 0 .225.12H.132A.07.07 0 0 0 .06.192v.006a.07.07 0 0 0 .06.071v.03a.1.1 0 0 1-.09-.1V.192A.1.1 0 0 1 .132.09M.36.211v-.03a.1.1 0 0 1 .09.101v.006A.1.1 0 0 1 .348.39H.255A.1.1 0 0 1 .154.288V.282A.1.1 0 0 1 .255.18H.27v.03H.255a.07.07 0 0 0-.071.072v.006A.07.07 0 0 0 .255.36h.093A.07.07 0 0 0 .42.288V.282A.07.07 0 0 0 .36.211"/></svg>
    </button>
  </div>



  <div *ngIf="selectedTicket != null && selectedTicket.id != null">
      <svg style="cursor:pointer" (click)="close()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 0.96 0.96" xml:space="preserve"><path d="M.87.48a.39.39 0 0 1-.39.39.39.39 0 0 1-.39-.39.39.39 0 0 1 .78 0" style="fill:#e54d2e"/><path d="M.48.09v.78a.39.39 0 0 0 0-.78" style="fill:#f27261"/><path d="M.48.09a.39.39 0 1 1-.001.781A.39.39 0 0 1 .48.09m0-.09a.48.48 0 1 0 0 .96.48.48 0 0 0 0-.96m.064.48L.618.406A.045.045 0 1 0 .554.342L.48.416.406.342a.045.045 0 1 0-.064.064L.416.48.342.554a.045.045 0 1 0 .064.064L.48.544l.074.074q.014.013.032.013C.604.631.609.627.618.618a.045.045 0 0 0 0-.064z" style="fill:#f4d6b0"/></svg>
  </div>
</div>

<div class="mt-3" *ngIf="mainLoading">
  <div class="flex flex-row justify-center items-center w-full  gap-4">
    <span class="m-auto  inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
  </div>
</div>

<div style="min-height:1px;border-bottom:1px solid #f3f4f8" >

</div>
<div class="px-8 py-5" *ngIf="selectedTicket != null && selectedTicket.id != null">

  <div class="flex flex-row justify-start gap-3">
    <div class=" text-xl font-bold">{{selectedTicket.ticketTypeName}} </div>
    <div>
      <div *ngIf="!showPriorityPicker" (click)="clickShowPriorities()" class="cursor-pointer m-auto justify-center">
        <div *ngIf="selectedTicket != null && selectedTicket.priority != null && selectedTicket.priority.includes('High')">
          <span class="badge bg-danger rounded-full">High</span>
        </div>
        <div *ngIf="selectedTicket != null && selectedTicket.priority != null && selectedTicket.priority.includes('Medium')">
          <span class="badge bg-warning rounded-full">Medium</span>
        </div>
        <div *ngIf="selectedTicket != null && selectedTicket.priority != null && selectedTicket.priority.includes('Low')">
          <span class="badge bg-success rounded-full">Low</span>
        </div>
        <div *ngIf="selectedTicket != null && (selectedTicket.priority == null || selectedTicket.priority == undefined)">
          <span class="badge rounded-full bg-gray-300 text-gray-500 hover:top-0">No Priority</span>
        </div>
      </div>


      <div *ngIf="showPriorityPicker">
        <select (change)="changeTicketPriority()"  class="select_input form-input form-input-focus" placeholder="Select Priority..."  [(ngModel)]="selectedPriority" name="selectedPriority">
          <option value="" disabled>{{'Please select a Priority' | translate}}</option>
          <option [value]="priority.id" *ngFor="let priority of priorities"> {{priority.name}}</option>
        </select>
      </div>

    </div>
  </div>


  <div class="grid grid-cols-5 gap-2 mt-6">
    <div class="flex flex-col justify-between">
      <div class="font-semibold">
        Status
      </div>
      <div>
        <span *ngIf="!showTicketStatuses" class="badge bg-warning rounded-full cursor-pointer text-white ticket-status-box"
              (click)="showTicketStatusesOnClick()"
              [ngStyle]="{'background-color': selectedTicket.ticketStatusColorCode, 'color' : selectedTicket.ticketStatusTextColor}">{{selectedTicket.ticketStatusName}}</span>

        <select (change)="changeTicketStatus()" *ngIf="showTicketStatuses" class="select_input form-input form-input-focus" placeholder="Select New Status..."  [(ngModel)]="selectedTicket.statusId" name="statusId">
          <option value="" disabled>{{'Please select a Status' | translate}}</option>
          <option [value]="status.id" *ngFor="let status of ticketStatuses"> {{status.name}}</option>
        </select>
      </div>
    </div>

    <div class="flex flex-col justify-between">
      <div class="font-semibold">
        Assignee
      </div>
      <div *ngIf="!showAssignees " style="padding:3px;border:1px dashed #D2D2D2; border-radius:20px" class="cursor-pointer ease-in-out duration-300 hover:bg-gray-100 grid grid-cols-4 items-center justify-start gap-1"
           (click)="clickShowAssignees()">
          <span *ngIf="selectedTicket.assignedId" class=" col-span-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                [ngStyle]="{'background':selectedTicket.assignedColorCode}">
            {{selectedTicket.assignedInitials}}
            <svg *ngIf="selectedTicket.assignedRepairCentreId != null" class="small-dlight-box-shadow" xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 12 12" xml:space="preserve" width="12" height="12"><path d="M10.967.777a.86.86 0 0 0-.608-.245c-.262 0-.526.111-.729.303l-.87.705-.033.031a.82.82 0 0 0-.221.735l-2.029 1.97-.34-.295c.243-.954.213-2.225-.609-3.048C4.925.333 4.155 0 3.357 0a2.8 2.8 0 0 0-.93.158.442.442 0 0 0-.195.705l1.094 1.306-.054.84-.909.104-1.298-1.085a.444.444 0 0 0-.705.195c-.353 1.023-.041 2.125.857 3.022.483.484 1.181.741 2.015.741q.093-.002.183-.006-.046.039-.092.082L.833 8.553a1.78 1.78 0 0 0 0 2.51l.417.417c.335.335.781.519 1.255.519s.92-.184 1.255-.519l2.492-2.493a1.7 1.7 0 0 0 .328-.459l2.213 2.558.021.023c.249.249.621.385 1.048.385.52 0 1.049-.205 1.378-.535.579-.579.763-1.815.149-2.427l-.023-.021-3.222-2.788 1.859-1.915q.076.014.153.014a.8.8 0 0 0 .579-.234l.736-.903c.169-.177.273-.399.298-.628a.87.87 0 0 0-.24-.708zM5.625 8.361l-2.492 2.493a.883.883 0 0 1-1.254 0l-.417-.417a.883.883 0 0 1 0-1.255l2.493-2.493a.87.87 0 0 1 .831-.236l1.101 1.27a.88.88 0 0 1-.258.638m4.991 1.971c-.165.165-.466.274-.753.274-.179 0-.333-.044-.412-.117L4.749 5.057a.45.45 0 0 0-.477-.129 3.3 3.3 0 0 1-1.04.171c-.585 0-1.077-.171-1.389-.481-.28-.28-.675-.777-.733-1.395l.826.692a.45.45 0 0 0 .335.1l1.467-.167a.44.44 0 0 0 .393-.412l.088-1.388a.45.45 0 0 0-.103-.313L3.408.888c.542.015 1.069.253 1.491.675.579.579.567 1.665.309 2.427a.45.45 0 0 0 .129.477l5.434 4.703c.197.216.151.855-.159 1.163m.215-8.259-.696.854-.024-.012-.302-.178-2.334 2.403-.327-.282 2.43-2.356-.178-.301-.012-.025.819-.665.033-.031a.2.2 0 0 1 .117-.062l.539.539a.2.2 0 0 1-.063.116"/></svg>
          </span>
        <span *ngIf="selectedTicket.assignedId" class="col-span-3 assignee-box" >
            {{selectedTicket.assignedName}}
          </span>

        <span *ngIf="selectedTicket.assignedId == null || selectedTicket.assignedId == undefined" class="flex flex-row justify-center gap-2 items-center w-auto" >
             <svg width="17" height="17" viewBox="0 0 0.765 0.765" xmlns="http://www.w3.org/2000/svg"><path d="M.383.361A.149.149 0 1 0 .234.212a.15.15 0 0 0 .149.149m0-.255a.106.106 0 1 1-.106.106.106.106 0 0 1 .106-.106" class="clr-i-outline clr-i-outline-path-1"/><path d="M.647.518a.365.365 0 0 0-.53 0 .04.04 0 0 0-.011.029v.112a.043.043 0 0 0 .043.043h.468A.043.043 0 0 0 .66.659V.547A.04.04 0 0 0 .649.518M.616.659H.149V.547a.32.32 0 0 1 .468 0Z" class="clr-i-outline clr-i-outline-path-2"/><path d="M0 0h.765v.765H0z" fill="none"/></svg>
            <span  >
            Not Assigned
          </span>
        </span>
      </div>

      <div *ngIf="showAssignees && selectedTicket.returnRepossessionTicketId == null && selectedTicket.afterSaleCustomerTicketId == null" style="position:relative">
        <input  type="text"  (keyup)="searchUsers()" [(ngModel)]="userSearch" name="userSearch" class="form-input form-input-focus" >
        <div  *ngIf="showUserDropdown" class="box-shadow-pop" style="border-radius:10px;position:absolute;z-index:999;top:40px;width:300px;min-height:30px;background-color: white">
          <div class="mt-5 mb-5" *ngIf="loadingUserSearch">
            <div class="flex flex-row justify-center w-full  gap-4">
              <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
            </div>
          </div>
          <ng-container *ngIf="!loadingUserSearch">
            <div (click)="selectUser(user)" *ngFor="let user of userSearchResults" style="padding:3px;padding-right:6px; border-radius:20px"
                 class="grid grid-cols-5 cursor-pointer ease-in-out duration-300 hover:bg-gray-100 flex flex-row items-center justify-start gap-3">
                  <span  class="col-span-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                         [ngStyle]="{'background':user.colorCode}">
                    {{user.initials}}
                    <svg *ngIf="user.repairCentreId != null"  xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 12 12" xml:space="preserve" width="12" height="12"><path d="M10.967.777a.86.86 0 0 0-.608-.245c-.262 0-.526.111-.729.303l-.87.705-.033.031a.82.82 0 0 0-.221.735l-2.029 1.97-.34-.295c.243-.954.213-2.225-.609-3.048C4.925.333 4.155 0 3.357 0a2.8 2.8 0 0 0-.93.158.442.442 0 0 0-.195.705l1.094 1.306-.054.84-.909.104-1.298-1.085a.444.444 0 0 0-.705.195c-.353 1.023-.041 2.125.857 3.022.483.484 1.181.741 2.015.741q.093-.002.183-.006-.046.039-.092.082L.833 8.553a1.78 1.78 0 0 0 0 2.51l.417.417c.335.335.781.519 1.255.519s.92-.184 1.255-.519l2.492-2.493a1.7 1.7 0 0 0 .328-.459l2.213 2.558.021.023c.249.249.621.385 1.048.385.52 0 1.049-.205 1.378-.535.579-.579.763-1.815.149-2.427l-.023-.021-3.222-2.788 1.859-1.915q.076.014.153.014a.8.8 0 0 0 .579-.234l.736-.903c.169-.177.273-.399.298-.628a.87.87 0 0 0-.24-.708zM5.625 8.361l-2.492 2.493a.883.883 0 0 1-1.254 0l-.417-.417a.883.883 0 0 1 0-1.255l2.493-2.493a.87.87 0 0 1 .831-.236l1.101 1.27a.88.88 0 0 1-.258.638m4.991 1.971c-.165.165-.466.274-.753.274-.179 0-.333-.044-.412-.117L4.749 5.057a.45.45 0 0 0-.477-.129 3.3 3.3 0 0 1-1.04.171c-.585 0-1.077-.171-1.389-.481-.28-.28-.675-.777-.733-1.395l.826.692a.45.45 0 0 0 .335.1l1.467-.167a.44.44 0 0 0 .393-.412l.088-1.388a.45.45 0 0 0-.103-.313L3.408.888c.542.015 1.069.253 1.491.675.579.579.567 1.665.309 2.427a.45.45 0 0 0 .129.477l5.434 4.703c.197.216.151.855-.159 1.163m.215-8.259-.696.854-.024-.012-.302-.178-2.334 2.403-.327-.282 2.43-2.356-.178-.301-.012-.025.819-.665.033-.031a.2.2 0 0 1 .117-.062l.539.539a.2.2 0 0 1-.063.116"/></svg>
                  </span>
              <span  class="flex flex-row col-span-4 assignee-box">

                    {{user.name}} <span *ngIf="user.repairCentreLevel != null">{{' - (L' + user.repairCentreLevel + ')'}}</span>
                  </span>
            </div>
          </ng-container>
        </div>
      </div>



<!--      <select [(ngModel)]="newAssignedUserId" (change)="changeAssignedUser(selectedTicket.id)" *ngIf="showAssignees" class="select_input form-input pr-1" placeholder="Select Assignee..."  name="assignedUserId">-->
<!--        <option value="" disabled>{{'Please select an Assignee' | translate}}</option>-->
<!--        <option [value]="user.id"  *ngFor="let user of possibleAssignees"> {{user.firstName + " "  + user.lastName}}</option>-->
<!--      </select>-->


    </div>

    <div class="flex flex-col justify-between">
      <div class="font-semibold">
        Reporter
      </div>
      <div class="grid grid-cols-4  items-center justify-start gap-1" style="padding-bottom:5px">
          <span *ngIf="selectedTicket.reporterFirstName" class="col-span-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                [ngStyle]="{'background':selectedTicket.reporterColorCode}">
            {{selectedTicket.reporterInitials}}
          </span>
        <span *ngIf="selectedTicket.reporterFirstName" class="col-span-3 assignee-box " >
            {{selectedTicket.reporterFirstName + ' ' + selectedTicket.reporterLastName}}
          </span>
      </div>
    </div>

    <div class="flex flex-col justify-between">
      <div  class="font-semibold">
        Date Created
      </div>
      <div class="flex flex-row items-center justify-start gap-3"  style="padding-bottom:5px">
        {{ moment(selectedTicket.dateCreated).format('DD MMMM YYYY HH:mm') }}

      </div>
    </div>

    <div class="flex flex-col justify-between">
      <div class="font-semibold">
        Time Tracking
      </div>
      <div class="flex flex-row items-center justify-start gap-3"  *ngIf="selectedTicket.resolutionStatusSlaEta == null" style="padding-bottom:5px">
        --
      </div>
      <div class="flex flex-row items-center justify-start gap-3 text-success font-medium"
           *ngIf="selectedTicket.resolutionStatusSlaGood == true" style="padding-bottom:5px">
        <span class="cursor-pointer" [tooltip]="'SLA met at ' +  moment(selectedTicket.resolutionStatusSlaDate).format('DD MMMM YYYY, HH:mm') ">{{ selectedTicket.resolutionStatusSlaText}}</span>
      </div>
      <div class="flex flex-row items-center justify-start gap-3 text-danger font-medium"
           *ngIf="selectedTicket.resolutionStatusSlaGood == false" style="padding-bottom:5px">
        <span class="cursor-pointer" [tooltip]="'SLA expired at ' +  moment(selectedTicket.resolutionStatusSlaEta).format('DD MMMM YYYY, HH:mm') ">
          {{ selectedTicket.resolutionStatusSlaText}}
          </span>
      </div>
      <div class="flex flex-row items-center justify-start gap-3 font-medium"
           *ngIf="selectedTicket.resolutionStatusSlaGood == null" style="padding-bottom:5px">
        <span class="cursor-pointer" [tooltip]="'SLA open, expires at ' +  moment(selectedTicket.resolutionStatusSlaEta).format('DD MMMM YYYY, HH:mm') ">
          {{ selectedTicket.resolutionStatusSlaText}}
        </span>
      </div>
    </div>

  </div>


  <div class="mt-8 p-3 box-shadow-pop pb-5" style="background-color:#f3f4f8;border-radius:20px">
    <div class="flex flex-row justify-between items-center">
      <div class="font-semibold text-lg">Customer Details</div>
      <button class="btn btn-dlight rounded-full flex-grow-0 cursor-pointer gap-3" (click)="openCustomerProfile(selectedTicket.customerIdEncrypted)">
        View Customer Profile
        <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 20 20" xml:space="preserve" width="20" height="20"><path d="M12.083 8.333h5.833a.417.417 0 0 0 .001-.833h-5.834a.417.417 0 0 0 0 .834"/><path d="M17.917.833H2.083A2.086 2.086 0 0 0 0 2.917v14.167c0 1.148.934 2.083 2.083 2.083h15.833a2.085 2.085 0 0 0 2.083-2.083V2.917A2.085 2.085 0 0 0 17.916.834M5.417 2.5a.417.417 0 1 1 0 .835.417.417 0 0 1 0-.835m-1.667 0a.417.417 0 1 1 0 .835.417.417 0 0 1 0-.835m-1.667 0a.417.417 0 1 1 0 .835.417.417 0 0 1 0-.835m17.083 14.583c0 .689-.561 1.25-1.25 1.25H2.083c-.689 0-1.25-.561-1.25-1.25V5h18.333z"/><path d="M2.083 15.833h8.333c.23 0 .417-.188.417-.418a2.27 2.27 0 0 0-1.721-2.204l-1.251-.313-.053-.209c.325-.358.555-.816.651-1.291a.78.78 0 0 0 .417-.596l.09-.725a.78.78 0 0 0-.186-.608.8.8 0 0 0-.152-.134l.021-.443.076-.076c.215-.228.505-.716.046-1.419-.217-.333-.667-.732-1.568-.732-.265 0-.864 0-1.446.365-1.715.06-1.917.984-1.917 1.683 0 .138.024.395.048.61a.8.8 0 0 0-.168.143.78.78 0 0 0-.188.612l.09.725a.76.76 0 0 0 .461.607c.093.454.312.894.616 1.243l-.061.245-1.251.313a2.27 2.27 0 0 0-1.722 2.204c0 .23.187.417.417.417m10.001.001h3.333a.417.417 0 0 0 .001-.833h-3.333a.417.417 0 0 0 0 .834m-.001-5.001h5.833a.417.417 0 0 0 .001-.833h-5.834a.417.417 0 0 0 0 .834m0 2.499h5.833a.417.417 0 0 0 .001-.833h-5.834a.417.417 0 0 0 0 .834"/></svg>
      </button>
    </div>
    <div class="mt-6 grid grid-cols-4 items-start gap-2">
      <div class="col-span-1 flex flex-col justify-between">
        <div class="font-semibold">Name</div>
        <div class="flex flex-row items-center justify-start gap-1">
          <div  style="color:#9499a1">{{selectedTicket.customerName}}</div>
          <span class="badge rounded-full bg-success/20 text-success hover:top-0">{{ this.selectedTicket.customerStatus }}</span>
        </div>
      </div>

      <div class="col-span-1 flex flex-col justify-between">
        <div class="font-semibold">Device(SKU)</div>
        <div style="color:#9499a1">
          {{selectedTicket.device}}
        </div>
      </div>

      <div class="col-span-1 flex flex-col justify-between">
        <div class="font-semibold">Account Number</div>
        <div style="color:#9499a1">
          {{selectedTicket.accountNumber}}
        </div>
      </div>

      <div class="col-span-1 flex flex-col justify-between">
        <div class="font-semibold">Payment Speed</div>
        <div  *ngIf="selectedTicket.paymentSpeed != null && selectedTicket.paymentSpeed == 'Blue'"><span class="badge rounded-full bg-info/20 text-info hover:top-0">Blue</span></div>
        <div  *ngIf="selectedTicket.paymentSpeed != null && selectedTicket.paymentSpeed == 'Green'"><span class="badge rounded-full bg-success/20 text-success hover:top-0">Green</span></div>
        <div  *ngIf="selectedTicket.paymentSpeed != null && selectedTicket.paymentSpeed == 'Yellow'"><span class="badge rounded-full bg-warning/20 text-warning hover:top-0">Yellow</span></div>
        <div  *ngIf="selectedTicket.paymentSpeed != null && selectedTicket.paymentSpeed == 'Red'"><span class="badge rounded-full bg-danger/20 text-danger hover:top-0">Red</span></div>
        <div  *ngIf="selectedTicket.paymentSpeed != null && selectedTicket.paymentSpeed == 'Grey'"><span class="badge rounded-full bg-gray-300 text-gray-500 hover:top-0">Grey</span></div>
        <div  *ngIf="selectedTicket.paymentSpeed == null">None</div>
      </div>



    </div>

    <div class="grid grid-cols-4 gap-3 " style="margin-top:15px">
      <div class="flex flex-col gap-2" >
        <div style="font-weight:600">Level 1 Disposition:</div>
        <div>{{selectedTicket.level1}}</div>
      </div>
      <div class="flex flex-col gap-2">
        <div  style="font-weight:600">Level 2 Disposition:</div>
        <div>{{selectedTicket.level2}}</div>
      </div>
      <div class="flex flex-col gap-2" >
        <div style="font-weight:600">Level 3 Disposition:</div>
        <div>{{selectedTicket.level3}}</div>
      </div>
      <div class="flex flex-col gap-2" >
        <div style="font-weight:600">Call Notes:</div>
        <div>{{selectedTicket.callLogNotes}}</div>
      </div>
    </div>

  </div>








  <div class="grid grid-cols-3 gap-5 mt-5">

    <div class="col-span-1 relative">
      <label class="label_title">{{'Tagged Users and Teams' | translate}}</label>
      <input  type="text" [disabled]="!can('can-tag-users-on-tickets')"  (keyup)="searchUsersTagged()" [(ngModel)]="userSearchTagged" name="userSearchTagged" class="form-input form-input-focus" >
      <div class="relative">
        <div  *ngIf="showUserDropdownTagged" class="box-shadow-pop" style="border-radius:10px;position:absolute;z-index:999;top:5px;min-width:100px;min-height:30px;background-color: white">
          <div class="mt-5 mb-5" *ngIf="loadingUserSearchTagged">
            <div class="flex flex-row justify-center w-full  gap-4">
              <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
            </div>
          </div>
          <ng-container *ngIf="!loadingUserSearchTagged">
            <div (click)="selectUserTagged(user)" *ngFor="let user of userSearchResultsTagged" style="padding:3px;padding-right:6px; border-radius:20px"
                 class="grid grid-cols-5 cursor-pointer ease-in-out duration-300 hover:bg-gray-100 flex flex-row items-center justify-start gap-1">
                  <span  class="flex h-8 w-8 col-span-1 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                         [ngStyle]="{'background':user.colorCode}">
                    {{user.initials}}

                    <svg *ngIf="user.repairCentreId != null" class="small-dlight-box-shadow" xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 12 12" xml:space="preserve" width="12" height="12"><path d="M10.967.777a.86.86 0 0 0-.608-.245c-.262 0-.526.111-.729.303l-.87.705-.033.031a.82.82 0 0 0-.221.735l-2.029 1.97-.34-.295c.243-.954.213-2.225-.609-3.048C4.925.333 4.155 0 3.357 0a2.8 2.8 0 0 0-.93.158.442.442 0 0 0-.195.705l1.094 1.306-.054.84-.909.104-1.298-1.085a.444.444 0 0 0-.705.195c-.353 1.023-.041 2.125.857 3.022.483.484 1.181.741 2.015.741q.093-.002.183-.006-.046.039-.092.082L.833 8.553a1.78 1.78 0 0 0 0 2.51l.417.417c.335.335.781.519 1.255.519s.92-.184 1.255-.519l2.492-2.493a1.7 1.7 0 0 0 .328-.459l2.213 2.558.021.023c.249.249.621.385 1.048.385.52 0 1.049-.205 1.378-.535.579-.579.763-1.815.149-2.427l-.023-.021-3.222-2.788 1.859-1.915q.076.014.153.014a.8.8 0 0 0 .579-.234l.736-.903c.169-.177.273-.399.298-.628a.87.87 0 0 0-.24-.708zM5.625 8.361l-2.492 2.493a.883.883 0 0 1-1.254 0l-.417-.417a.883.883 0 0 1 0-1.255l2.493-2.493a.87.87 0 0 1 .831-.236l1.101 1.27a.88.88 0 0 1-.258.638m4.991 1.971c-.165.165-.466.274-.753.274-.179 0-.333-.044-.412-.117L4.749 5.057a.45.45 0 0 0-.477-.129 3.3 3.3 0 0 1-1.04.171c-.585 0-1.077-.171-1.389-.481-.28-.28-.675-.777-.733-1.395l.826.692a.45.45 0 0 0 .335.1l1.467-.167a.44.44 0 0 0 .393-.412l.088-1.388a.45.45 0 0 0-.103-.313L3.408.888c.542.015 1.069.253 1.491.675.579.579.567 1.665.309 2.427a.45.45 0 0 0 .129.477l5.434 4.703c.197.216.151.855-.159 1.163m.215-8.259-.696.854-.024-.012-.302-.178-2.334 2.403-.327-.282 2.43-2.356-.178-.301-.012-.025.819-.665.033-.031a.2.2 0 0 1 .117-.062l.539.539a.2.2 0 0 1-.063.116"/></svg>
                  </span>
              <span  class="col-span-4 assignee-box">
                    {{user.name}}
                  </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-span-1 flex flex-col justify-end">
      <div>
        <div *ngFor="let user of selectedTicket.taggedUsers" class="avatar-box stacked " style="position:relative;margin-left:-8px;display:inline-block;cursor:pointer;"  [tooltip]="user.name" >
                <span id="example-avatar" (click)="removeTaggedUser(user)" class="cursor-pointer avatar avatar-sm avatar-shadow" style="outline-style:solid;outline-width: 1px;" [ngStyle]="{'background': user.colorCode, 'color':'white'}">
                  <div class="flex flex-row justify-center items-center">
                    <div>
                      {{user.initials}}
                    </div>
                    <svg *ngIf="user.repairCentreId != null"  xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 12 12" xml:space="preserve" width="12" height="12"><path d="M10.967.777a.86.86 0 0 0-.608-.245c-.262 0-.526.111-.729.303l-.87.705-.033.031a.82.82 0 0 0-.221.735l-2.029 1.97-.34-.295c.243-.954.213-2.225-.609-3.048C4.925.333 4.155 0 3.357 0a2.8 2.8 0 0 0-.93.158.442.442 0 0 0-.195.705l1.094 1.306-.054.84-.909.104-1.298-1.085a.444.444 0 0 0-.705.195c-.353 1.023-.041 2.125.857 3.022.483.484 1.181.741 2.015.741q.093-.002.183-.006-.046.039-.092.082L.833 8.553a1.78 1.78 0 0 0 0 2.51l.417.417c.335.335.781.519 1.255.519s.92-.184 1.255-.519l2.492-2.493a1.7 1.7 0 0 0 .328-.459l2.213 2.558.021.023c.249.249.621.385 1.048.385.52 0 1.049-.205 1.378-.535.579-.579.763-1.815.149-2.427l-.023-.021-3.222-2.788 1.859-1.915q.076.014.153.014a.8.8 0 0 0 .579-.234l.736-.903c.169-.177.273-.399.298-.628a.87.87 0 0 0-.24-.708zM5.625 8.361l-2.492 2.493a.883.883 0 0 1-1.254 0l-.417-.417a.883.883 0 0 1 0-1.255l2.493-2.493a.87.87 0 0 1 .831-.236l1.101 1.27a.88.88 0 0 1-.258.638m4.991 1.971c-.165.165-.466.274-.753.274-.179 0-.333-.044-.412-.117L4.749 5.057a.45.45 0 0 0-.477-.129 3.3 3.3 0 0 1-1.04.171c-.585 0-1.077-.171-1.389-.481-.28-.28-.675-.777-.733-1.395l.826.692a.45.45 0 0 0 .335.1l1.467-.167a.44.44 0 0 0 .393-.412l.088-1.388a.45.45 0 0 0-.103-.313L3.408.888c.542.015 1.069.253 1.491.675.579.579.567 1.665.309 2.427a.45.45 0 0 0 .129.477l5.434 4.703c.197.216.151.855-.159 1.163m.215-8.259-.696.854-.024-.012-.302-.178-2.334 2.403-.327-.282 2.43-2.356-.178-.301-.012-.025.819-.665.033-.031a.2.2 0 0 1 .117-.062l.539.539a.2.2 0 0 1-.063.116"/></svg>
                  </div>
                </span>

        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedTicket.customFields != null && selectedTicket.customFields.length > 0" class="mt-8">
    <div class="font-semibold text-lg">Additional Fields</div>
    <div class="text-dlight">
      <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
    </div>

    <div class="grid grid-cols-1 gap-3">
      <div *ngFor="let customField of selectedTicket.customFields" >
        <div class="col-span-1 mt-3" *ngIf="customField.customFieldTypeId == 1">
          <div class="flex flex-row justify-start gap-3 items-baseline mt-3">
            <label class="label_title">{{customField.name}}</label>
            <div *ngIf="customField.description" class="text-sm">({{customField.description}})</div>
          </div>
          <input (change)="updateCustomField(customField)" type="text" class="form-input form-input-focus" placeholder="Input Answer" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">
        </div>

        <div class="col-span-1 mt-3" *ngIf="customField.customFieldTypeId == 2">
          <div class="flex flex-row justify-start gap-3 items-baseline mt-3">
            <label class="label_title">{{customField.name}}</label>
            <div *ngIf="customField.description" class="text-sm">({{customField.description}})</div>
          </div>
          <input (change)="updateCustomField(customField)" type="date" placeholder="Select Date" class="form-input form-input-focus" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">
        </div>

        <div class="col-span-1 mt-3" *ngIf="customField.customFieldTypeId == 3">
          <div class="flex flex-row justify-start gap-3 items-baseline mt-3">
            <label class="label_title">{{customField.name}}</label>
            <div *ngIf="customField.description" class="text-sm">({{customField.description}})</div>
          </div>
          <select (change)="updateCustomField(customField)" class="select_input form-input form-input-focus" placeholder="Select an Option..."  [(ngModel)]="customField.answer"  [ngModelOptions]="{standalone: true}">
            <option value="" disabled>{{'Please select an account number' | translate}}</option>
            <option [value]="option.value" *ngFor="let option of customField.customFieldOptions"> {{option.value}}</option>
          </select>
        </div>

      </div>
    </div>

    <ng-container *ngFor="let customField of selectedTicket.customFields">
      <div *ngIf="customField.customFieldTypeId == 4 && customField.name == 'Promise To Pay Amount'" >
        <div class="flex flex-row justify-start gap-3 items-baseline mt-3">
          <label class="label_title">{{customField.name}}</label>
          <div *ngIf="customField.description" class="text-sm"></div>
        </div>
        <input (change)="updateCustomField(customField)" type="text" class="form-input form-input-focus" placeholder="Input Answer" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">
      </div>

      <div *ngIf="customField.customFieldTypeId == 4 && customField.name == 'Promise To Pay Date'" >
        <div class="flex flex-row justify-start gap-3 items-baseline mt-3">
          <label class="label_title">{{customField.name}}</label>
          <div *ngIf="customField.description" class="text-sm"></div>
        </div>
        <input (change)="updateCustomField(customField)" type="date" class="form-input form-input-focus" placeholder="Input Answer" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">
      </div>
    </ng-container>

  </div>


  <!--  <div *ngIf="selectedTicket.customFields != null && selectedTicket.customFields.length > 0" class="mt-8">-->
<!--    <div class="font-semibold text-lg">Additional Fields</div>-->
<!--    <div class="grid grid-cols-2 gap-3">-->
<!--      <div *ngFor="let customField of selectedTicket.customFields" class="mt-3">-->

<!--        <div class="col-span-1"  *ngIf="customField.customFieldTypeId == 1">-->
<!--          <label class="label_title">{{customField.name}}-->
<!--            <span *ngIf="customField.mandatory && (customField.answer == null || customField.answer == undefined)" style="color:red">*</span>-->
<!--            <span *ngIf="customField.mandatory && customField.answer != null && customField.answer != undefined" style="color:green">*</span>-->
<!--          </label>-->
<!--          <input type="text" (change)="updateCustomField(customField)" class="form-input form-input-focus" placeholder="Input Answer" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">-->
<!--        </div>-->

<!--        <div class="col-span-1" *ngIf="customField.customFieldTypeId == 2">-->
<!--          <label class="label_title">{{customField.name}}-->
<!--            <span *ngIf="customField.mandatory && (customField.answer == null || customField.answer == undefined)" style="color:red">*</span>-->
<!--            <span *ngIf="customField.mandatory && customField.answer != null && customField.answer != undefined" style="color:green">*</span>-->
<!--          </label>-->
<!--          <input type="date" (change)="updateCustomField(customField)" placeholder="Select Date" class="form-input form-input-focus" [ngModelOptions]="{standalone: true}" [(ngModel)]="customField.answer">-->
<!--        </div>-->

<!--        <div class="col-span-1" *ngIf="customField.customFieldTypeId == 3">-->
<!--          <label class="label_title">{{customField.name}}-->
<!--            <span *ngIf="customField.mandatory && (customField.answer == null || customField.answer == undefined)" style="color:red">*</span>-->
<!--            <span *ngIf="customField.mandatory && customField.answer != null && customField.answer != undefined" style="color:green">*</span>-->
<!--          </label>-->
<!--          <select (change)="updateCustomField(customField)" class="select_input form-input form-input-focus" placeholder="Select an Option..."  [(ngModel)]="customField.answer"  [ngModelOptions]="{standalone: true}">-->
<!--            <option value="" disabled>{{'Please select an account number' | translate}}</option>-->
<!--            <option [value]="option.value"  *ngFor="let option of customField.customFieldOptions"> {{option.value}}</option>-->
<!--          </select>-->
<!--        </div>-->


<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <ng-container *ngIf="selectedTicket.afterSaleCustomerTicketId">
    <warranty-ticket-view (closeEvent)="closeViewModal()" [setTicket]="selectedTicket"></warranty-ticket-view>
  </ng-container>

  <ng-container *ngIf="selectedTicket.returnRepossessionTicketId">
    <repossession-ticket-view (closeEvent)="closeViewModal()" [setReturnTicket]="selectedTicket.returnRepossessionTicketWrapper"></repossession-ticket-view>
  </ng-container>

  <div *ngIf="selectedTicket.attachments != null && selectedTicket.attachments.length > 0" class="mt-6">
    <div class="font-semibold text-lg">File Attachments </div>
    <div class="grid grid-cols-3 gap-2" >

      <div *ngFor="let file of selectedTicket.attachments" (click)="downloadFile(file)" [ngClass]="{'cursor-pointer' : file.finished}" class=" col-span-1 grid grid-cols-4 gap-1 border border-sky-100 rounded-xl p-1 hover:bg-gray-100 ease-in-out duration-300 " style="max-height:120px">
        <div class="col-span-2 items-center flex flex-row justify-center ">
          <div class="flex items-center">
            <ng-container *ngIf="file.finished">
              <svg *ngIf="!file.image" width="40" height="40" viewBox="0 0 1.2 1.2" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.447 0.997 0.433V0.428L0.987 0.415l-0.3 -0.3L0.673 0.105H0.668L0.653 0.1H0.35a0.15 0.15 0 0 0 -0.15 0.15v0.7a0.15 0.15 0 0 0 0.15 0.15h0.5A0.15 0.15 0 0 0 1 0.95zM0.7 0.27 0.83 0.4H0.7ZM0.9 0.95A0.05 0.05 0 0 1 0.85 1h-0.5A0.05 0.05 0 0 1 0.3 0.95V0.25A0.05 0.05 0 0 1 0.35 0.2h0.25v0.25A0.05 0.05 0 0 0 0.65 0.5h0.25Z"/></svg>
              <img style="max-height:110px" *ngIf="file.image" [src]="file.src">

            </ng-container>
            <div *ngIf="!file.finished" class="flex flex-row justify-center items-center">
              <span class="m-auto w-8 h-8  inline-block animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
            </div>
          </div>

        </div>

        <div class="col-span-1 flex items-center break-all	" style="text-overflow: ellipsis;">
          {{file.name}}
        </div>

        <div class="col-span-1 flex flex-row justify-end items-center">
                <span  class="badge bg-success rounded-full ng-tns-c282470920-0" style="background-color: #fbd9d4; color: #ed6754;">
                  {{file.name.split('.').pop()}}
                </span>
        </div>

      </div>
    </div>
  </div>




  <div class="mt-8">
    <div class="font-semibold text-lg">Notes</div>
    <div class="text-dlight">
      <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
    </div>
  </div>

  <div class="mt-3">
    <input type="file" hidden  multiple="multiple"  id="imageUploadButton" style="display:none;" (change)="onImageSelected($event)">
    <ng-container *ngIf="!notesEditable">
      <editor
        [(ngModel)]="selectedTicket.notes"
        name="mainNotes" id="mainNotes"
        (onInit)="onReadyTinyMainNotes($event)"
        [init]="emptyConfig"></editor>
    </ng-container>
    <ng-container *ngIf="notesEditable">
      <editor

        [(ngModel)]="selectedTicket.notes"
        name="mainNotes" id="mainNotes"
        (onInit)="onReadyTinyMainNotes($event)"
        [init]="editableConfigMainNotes"></editor>

      <div class="mt-3 flex flex-row justify-end" *ngIf="!savingNotes">
        <button class="btn btn-dlight flex-grow-0 gap-3" (click)="saveNotes()" >
          Save
          <svg width="20" height="20" viewBox="0 0 0.6 0.6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.084.224a.19.19 0 0 1 .14-.14.3.3 0 0 1 .152 0 .19.19 0 0 1 .14.14.3.3 0 0 1 0 .152.19.19 0 0 1-.14.14.3.3 0 0 1-.152 0 .19.19 0 0 1-.14-.14.3.3 0 0 1 0-.152Z" stroke="white" stroke-width=".07"/><path d="m.231.294.05.05L.369.25" stroke="white" stroke-width=".07" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </button>
      </div>
      <div class="flex flex-row justify-center w-full mt-3 gap-4" *ngIf="savingNotes">
        <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
      </div>

    </ng-container>

  </div>




  <div class="tabs-wrapper mt-3 mb-2">
    <div class="tabs" style="border-bottom: 1px dashed #D2D2D2">
      <p class="tab" (click)="detailsTab = 1" [ngClass]="{'active' : detailsTab == 1}">Comments</p>
      <p class="tab" (click)="downloadTicketActions(selectedTicket.id);detailsTab = 2"  [ngClass]="{'active' : detailsTab == 2}">Actions</p>
      <p class="tab" (click)="downloadTicketActivity(selectedTicket.id);detailsTab = 3"  [ngClass]="{'active' : detailsTab == 3}">Activity History</p>
    </div>
  </div>

  <div *ngIf="detailsTab == 1">

    <ng-container *ngIf="loadingDetails">
      <div class="flex flex-row justify-center w-full  gap-4">
        <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
      </div>
    </ng-container>


    <input type="file" hidden  multiple="multiple"  id="existingCommentUploadButton" style="display:none;" (change)="onImageSelectedExistingComment($event)">

    <div *ngFor="let comment of selectedTicket.comments" class="p-10 comment-panel ease-in-out duration-600">


      <div class="small-box-shadow rounded-xl p-4 ease-in-out duration-600">

        <div class="flex flex-row justify-between">
          <div class="flex flex-row justify-start items-center gap-2">
                    <span *ngIf="comment.firstName" class="flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                          [ngStyle]="{'background':comment.colorCode}">
                  {{comment.initials}}
                  </span>
            <span class="font-semibold ">{{comment.firstName + ' ' + comment.lastName}}</span>
          </div>

          <div>
            <div class="flex justify-end">{{ moment(comment.date).format('DD MMMM YYYY') }}</div>
            <div class="flex justify-end" style="color:#9499a1">{{ moment(comment.date).format('HH:mm') }}</div>
          </div>
        </div>


        <div *ngIf="comment.attachments != null && comment.attachments.length > 0" class="mt-1 mb-1">
          <div class="grid grid-cols-3 gap-2 mt-2" >

            <div *ngFor="let file of comment.attachments" (click)="downloadFile(file)" [ngClass]="{'cursor-pointer' : file.finished}" class=" col-span-1 grid grid-cols-4 gap-1 border border-sky-100 rounded-xl p-1 hover:bg-gray-100 ease-in-out duration-300 " style="max-height:120px">
              <div class="col-span-2 items-center flex flex-row justify-center ">
                <div class="flex items-center">
                  <ng-container *ngIf="file.finished">
                    <svg *ngIf="!file.image" width="40" height="40" viewBox="0 0 1.2 1.2" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.447 0.997 0.433V0.428L0.987 0.415l-0.3 -0.3L0.673 0.105H0.668L0.653 0.1H0.35a0.15 0.15 0 0 0 -0.15 0.15v0.7a0.15 0.15 0 0 0 0.15 0.15h0.5A0.15 0.15 0 0 0 1 0.95zM0.7 0.27 0.83 0.4H0.7ZM0.9 0.95A0.05 0.05 0 0 1 0.85 1h-0.5A0.05 0.05 0 0 1 0.3 0.95V0.25A0.05 0.05 0 0 1 0.35 0.2h0.25v0.25A0.05 0.05 0 0 0 0.65 0.5h0.25Z"/></svg>
                    <img style="max-height:110px" *ngIf="file.image" [src]="file.src">

                  </ng-container>
                  <div *ngIf="!file.finished" class="flex flex-row justify-center items-center">
                    <span class="m-auto w-8 h-8  inline-block animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
                  </div>
                </div>

              </div>

              <div class="col-span-1 flex items-center break-all	" style="text-overflow: ellipsis;">
                {{file.name}}
              </div>

              <div class="col-span-1 flex flex-row justify-end items-center">
                <span  class="badge bg-success rounded-full ng-tns-c282470920-0" style="background-color: #fbd9d4; color: #ed6754;">
                  {{file.name.split('.').pop()}}
                </span>
              </div>

            </div>
          </div>
        </div>


        <div class="mt-3">
          <ng-container *ngIf="comment.userId != userId">
            <editor
              [(ngModel)]="comment.content"
              name="{{'comment' + comment.id}}" id="{{'comment' + comment.id}}"
              [init]="emptyConfig"
              (onInit)="onReadyTinyExistingComments($event, comment.id)"
            ></editor>
          </ng-container>
          <ng-container *ngIf="comment.userId == userId">

            <editor
              *ngIf="comment.id != editingComment.id"
              [(ngModel)]="comment.content"
              name="{{'comment' + comment.id}}" id="{{'comment' + comment.id}}"
              [init]="emptyConfig"
              (onInit)="onReadyTinyExistingComments($event, comment.id)"
            ></editor>

            <div class="flex flex-row mt-3 justify-end" *ngIf="editingComment.id == null || editingComment.id == 0">
              <button class="btn btn-dlight gap-3" (click)="selectCommentForEditing(comment);">
                Edit
                <svg width="20" height="20" fill="white" viewBox="0 0 0.6 0.6" xmlns="http://www.w3.org/2000/svg"><path d="M.525.3A.025.025 0 0 0 .5.325v.15A.025.025 0 0 1 .475.5h-.35A.025.025 0 0 1 .1.475v-.35A.025.025 0 0 1 .125.1h.15a.025.025 0 0 0 0-.05h-.15A.075.075 0 0 0 .05.125v.35A.075.075 0 0 0 .125.55h.35A.075.075 0 0 0 .55.475v-.15A.025.025 0 0 0 .525.3M.15.319v.106A.025.025 0 0 0 .175.45h.106A.03.03 0 0 0 .299.443L.472.27.543.2a.025.025 0 0 0 0-.035L.437.058a.025.025 0 0 0-.035 0l-.07.071-.174.173A.03.03 0 0 0 .151.32M.42.111l.071.071-.035.035L.385.146ZM.2.329.348.181l.071.071L.271.4H.2Z"/></svg>
              </button>
            </div>

            <editor
              *ngIf="comment.id == editingComment.id"
              [(ngModel)]="comment.content"
              name="{{'editingComment' + comment.id}}" id="{{'editingComment' + comment.id}}"
              [init]="editableConfigComment"
              (onInit)="onReadyTinyMyComments($event, comment.id)"
            ></editor>

            <div class="flex flex-row gap-2 mt-3 justify-end" *ngIf="editingComment.id == comment.id && !updatingComment">
              <button class="btn btn-dlight gap-3" (click)="cancelUpdatingComment();">
                Cancel
                <svg width="20" height="20" fill="white" viewBox="0 0 0.4 0.4" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M.2.025a.175.175 0 1 1 0 .35.175.175 0 0 1 0-.35M.075.2a.125.125 0 0 0 .194.104L.096.131A.13.13 0 0 0 .075.2M.2.075a.13.13 0 0 0-.069.021l.173.173A.125.125 0 0 0 .2.075"/></svg>
              </button>
              <button class="btn btn-success gap-3" (click)="updateComment();">
                Save
                <svg width="20" height="20" viewBox="0 0 0.6 0.6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.084.224a.19.19 0 0 1 .14-.14.3.3 0 0 1 .152 0 .19.19 0 0 1 .14.14.3.3 0 0 1 0 .152.19.19 0 0 1-.14.14.3.3 0 0 1-.152 0 .19.19 0 0 1-.14-.14.3.3 0 0 1 0-.152Z" stroke="white" stroke-width=".07"/><path d="m.231.294.05.05L.369.25" stroke="white" stroke-width=".07" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </button>
            </div>

            <ng-container *ngIf="editingComment.id == comment.id && updatingComment">
              <div class="flex flex-row justify-center w-full mt-3  gap-4">
                <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
              </div>
            </ng-container>

          </ng-container>
        </div>


      </div>
    </div>


    <!-- NEW COMMENT -->


    <div style="min-height:1px;border-bottom:1px solid #f3f4f8" ></div>

    <div class="mt-10">
      <div class="font-semibold text-lg">New Comment</div>
    </div>

    <div class="small-box-shadow rounded-xl p-4 mt-3">
      <div *ngIf="newComment.attachments != null && newComment.attachments.length > 0" class="mt-3">
        <div class="font-semibold">File Attachments </div>
        <div class="grid grid-cols-3 gap-2">

          <div *ngFor="let file of newComment.attachments" (click)="downloadFile(file)" [ngClass]="{'cursor-pointer' : file.finished}" class=" col-span-1 grid grid-cols-4 gap-1 border border-sky-100 rounded-xl p-1 hover:bg-gray-100 ease-in-out duration-300 " style="max-height:120px">
            <div class="col-span-2 items-center flex flex-row justify-center ">
              <div class="flex items-center">
                <ng-container *ngIf="file.finished">
                  <svg *ngIf="!file.image" width="40" height="40" viewBox="0 0 1.2 1.2" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.447 0.997 0.433V0.428L0.987 0.415l-0.3 -0.3L0.673 0.105H0.668L0.653 0.1H0.35a0.15 0.15 0 0 0 -0.15 0.15v0.7a0.15 0.15 0 0 0 0.15 0.15h0.5A0.15 0.15 0 0 0 1 0.95zM0.7 0.27 0.83 0.4H0.7ZM0.9 0.95A0.05 0.05 0 0 1 0.85 1h-0.5A0.05 0.05 0 0 1 0.3 0.95V0.25A0.05 0.05 0 0 1 0.35 0.2h0.25v0.25A0.05 0.05 0 0 0 0.65 0.5h0.25Z"/></svg>
                  <img style="max-height:120px" *ngIf="file.image" [src]="file.src">

                </ng-container>
                <div *ngIf="!file.finished" class="flex flex-row justify-center items-center">
                  <span class="m-auto w-8 h-8  inline-block animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
                </div>
              </div>

            </div>

            <div class="col-span-1 flex items-center break-all	" style="text-overflow: ellipsis;">
              {{file.name}}
            </div>

            <div class="col-span-1 flex flex-row justify-end items-center">
                <span  class="badge bg-success rounded-full ng-tns-c282470920-0" style="background-color: #fbd9d4; color: #ed6754;">
                  {{file.name.split('.').pop()}}
                </span>
            </div>

          </div>
        </div>
      </div>


      <div class="flex flex-row justify-between mt-3">
        <div class="flex flex-row justify-start items-center gap-2">
                    <span *ngIf="currentUserDetails.firstName" class="flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                          [ngStyle]="{'background':currentUserDetails.colorCode}">
                  {{currentUserDetails.initials}}
                  </span>
          <span class="font-semibold ">{{currentUserDetails.firstName + ' ' + currentUserDetails.lastName}}</span>
        </div>


      </div>

      <input type="file" hidden  multiple="multiple"  id="imageUploadButtonNewComment" style="display:none;" (change)="onImageSelectedNewComment($event)">

      <div class="mt-3">
        <editor
          [(ngModel)]="newComment.content"
          name="newCommentContent" id="newCommentContent"
          [init]="newCommentConfig"></editor>
      </div>

      <div  class="mt-2 flex flex-row justify-end">
        <ng-container *ngIf="addingComment">
          <div class="flex flex-row justify-center w-full  gap-4">
            <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
          </div>
        </ng-container>

        <button *ngIf="!addingComment" class="btn btn-dlight flex-grow-0 gap-3" (click)="createComment()">
          Add Comment
          <svg width="20" height="20" viewBox="0 0 0.6 0.6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.108.166.13.177zm.011.34L.101.488zm.34-.089L.47.439zM.492.384l.022.011zm0-.218L.514.155zM.459.133.47.111zm-.318 0 .011.022zm.052.299L.175.415zM.125.245.126.198.13.178.085.155a.1.1 0 0 0-.01.039v.051zm0 .055V.245h-.05V.3zm-.05 0v.125h.05V.3zm0 .125v.073h.05V.425zm0 .073c0 .032.039.048.062.026L.102.489a.014.014 0 0 1 .023.01zm.062.026L.21.45.175.415.102.488zM.38.4H.21v.05h.17zM.448.395a.04.04 0 0 1-.02.004L.381.4v.05L.432.449a.1.1 0 0 0 .039-.01zM.47.373a.05.05 0 0 1-.022.022L.471.44A.1.1 0 0 0 .515.396zM.475.305.474.352.47.372l.045.023a.1.1 0 0 0 .01-.039L.526.305zm0-.06v.06h.05v-.06zM.47.177q.004.006.004.02l.001.048h.05L.524.194a.1.1 0 0 0-.01-.039zM.448.155A.05.05 0 0 1 .47.177L.515.154A.1.1 0 0 0 .471.11zM.38.15l.067.005L.47.11A.1.1 0 0 0 .431.1H.38zm-.16 0h.16V.1H.22zM.152.155a.04.04 0 0 1 .02-.004L.22.15V.1L.169.101a.1.1 0 0 0-.039.01zM.13.177A.05.05 0 0 1 .152.155L.129.11a.1.1 0 0 0-.044.044zM.21.45V.4a.05.05 0 0 0-.035.015z" fill="white"/><path d="M.2.225h.2m-.2.1h.125" stroke="white" stroke-width=".05" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </button>
      </div>


    </div>


  </div>

  <div *ngIf="detailsTab == 2">
    <ng-container *ngIf="loadingDetails">
      <div class="flex flex-row justify-center w-full  gap-4">
        <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
      </div>
    </ng-container>
    <ng-container *ngIf="!loadingDetails">
      <div *ngFor="let action of selectedTicket.actionList" class="p-3">
        <div class="small-box-shadow rounded-xl p-4">

          <div class="flex flex-row justify-between">
            <div class="flex flex-row justify-start items-center gap-2">
              <span class="font-semibold ">{{action.actionType}}</span>
            </div>

            <div>
              <div class="flex justify-end">{{ moment(action.date).format('DD MMMM YYYY') }}</div>
              <div class="flex justify-end" style="color:#9499a1">{{ moment(action.date).format('HH:mm') }}</div>
            </div>
          </div>

          <div class="mt-3">
            {{action.description}}
          </div>


        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="detailsTab == 3">
    <ng-container *ngIf="loadingDetails">
      <div class="flex flex-row justify-center w-full  gap-4">
        <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
      </div>
    </ng-container>
    <ng-container *ngIf="!loadingDetails">
      <div *ngFor="let activity of selectedTicket.activityList" class="p-3">
        <div class="small-box-shadow rounded-xl p-4">

          <div class="flex flex-row justify-between">
            <div class="flex flex-row justify-start items-center gap-2">
                    <span *ngIf="activity.firstName" class="flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                          [ngStyle]="{'background':activity.colorCode}">
                  {{activity.initials}}
                  </span>
              <span class="font-semibold ">{{activity.firstName + ' ' + activity.lastName}}</span>
            </div>

            <div>
              <div class="flex justify-end">{{ moment(activity.date).format('DD MMMM YYYY') }}</div>
              <div class="flex justify-end" style="color:#9499a1">{{ moment(activity.date).format('HH:mm') }}</div>
            </div>
          </div>

          <div class="mt-3">
            {{activity.description}}
          </div>


        </div>
      </div>
    </ng-container>
  </div>



</div>
<div class="modal-footer">
</div>

<!--<button id="openReassignWarrantyTicketModalButton"  [hidden]="true"-->
<!--        data-toggle="modal"  (click)="openReassignWarrantyTicketModal(reassignWarrantyTicketModal)" ></button>-->
<!--<button id="openFollowUpCallButton"  [hidden]="true"-->
<!--        data-toggle="modal"  (click)="openFollowUpCallModalModal(followUpCallModal)" ></button>-->
<!--<button id="openCloseWarrantyTicketModalButton"  [hidden]="true"-->
<!--        data-toggle="modal"  (click)="openCloseWarrantyTicketModal(closeWarrantyTicketModal)" ></button>-->
<!--<ng-template #reassignWarrantyTicketModal>-->

<!--  <div class="m-auto w-auto pt-8">-->
<!--    <label class="label_title">{{'Repair Centre' | translate}} *</label>-->
<!--    <select2-->
<!--      [options]="repairCentreNames"-->
<!--      (onSelect)="changeRepairCentre($event)"-->
<!--      [(ngModel)]="newRepairCentreId"-->
<!--      name="newRepairCentreId">-->
<!--    </select2>-->
<!--  </div>-->

<!--  <div class="mt-3 w-full flex flex-row justify-center pb-4">-->
<!--    <button type="button" class="btn btn-success">Reassign</button>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #closeWarrantyTicketModal>-->

<!--  <div class="w-auto m-auto pt-5">-->
<!--  <div class="input">-->
<!--    <label style="font-weight: bold">{{'Closing Comment' | translate}} *</label>-->
<!--    <textarea class="form-input form-input-focus" [(ngModel)]="closingComment" name="closingComment"></textarea>-->
<!--  </div>-->

<!--  <div class="input">-->
<!--    <label class="label_title">{{'Disposition' | translate}}:</label>-->
<!--    <select class="form-input form-input-focus" (change)="selectClosingDisposition($event.target.value)" name="dispositionClosing">-->
<!--      <option selected value="">{{'Select one' | translate}}</option>-->
<!--      <option value="{{disposition.id}}"-->
<!--              *ngFor="let disposition of ticketClosingDispositions">{{disposition.name}}-->
<!--      </option>-->
<!--    </select>-->
<!--  </div>-->
<!--  </div>-->

<!--  <div class="mt-3 w-full flex flex-row justify-center pb-4">-->
<!--    <button type="button" class="btn btn-success" (click)="closeWarrantyTicket()">Close Ticket</button>-->
<!--  </div>-->

<!--</ng-template>-->

<!--<ng-template #followUpCallModal>-->

<!--  <div class="w-auto m-auto pt-5">-->
<!--    <div class="input ">-->
<!--      <label class="label_title">{{'Disposition' | translate}}:</label>-->
<!--      <select [(ngModel)]="followupDisposition" class="form-input form-input-focus" name="followupDisposition">-->
<!--        <option selected value="">{{'Select one' | translate}}</option>-->
<!--        <option value="{{disposition}}"-->
<!--                *ngFor="let disposition of followupDispositions">{{disposition}}-->
<!--        </option>-->
<!--      </select>-->
<!--    </div>-->

<!--    <div class="input mt-3" >-->
<!--      <label class="label_title">{{'Notes' | translate}}:</label>-->
<!--      <textarea class="form-input form-input-focus" [(ngModel)]="followupNotes" name="followupNotes" rows="3"></textarea>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="mt-3 w-full flex flex-row justify-center pb-4">-->
<!--    <button type="button" class="btn btn-success" (click)="followupTicket()">Follow Up</button>-->
<!--  </div>-->

<!--</ng-template>-->
