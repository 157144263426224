import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CustomerService} from "@appServices/core-crm/customer/customer.service";
import {CoreTicketingCustomerService} from "@appServices/core-ticketing/customer/core-ticketing-customer.service";
import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";
import {ToastrService} from "ngx-toastr";
import {TicketingFileService} from "@appServices/core-ticketing/file/ticketing-file-service";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {TeamListRow} from "@appModels/ticketing/team.list.row";
import {PermissionResolver} from "@appServices/permission-resolver/permission-resolver.service";

@Component({
  moduleId: module.id,
  templateUrl: './teams-table-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./teams-table-component.css', '../../../tailwind.css']
})
export class TeamsTableComponent {
  constructor(
    private toastrService: ToastrService,
    private ticketFileService: TicketingFileService,
    private ref: ChangeDetectorRef,
    private permissionResolverService: PermissionResolver,
    private modalService: BsModalService,
    private customerService: CustomerService,
    private ticketingService: TicketingService,
    private coreTicketingCustomerService: CoreTicketingCustomerService,
    private teamsService: TeamsService
  ) {
    this.getTeams();
  }

  loadingTable = true;

  getTeams() {
    this.teamsService.getTeams().subscribe(x=> {
      this.teams = x;
      this.loadingTable = false;
    })
  }

  teams : TeamListRow[] = [];

  can(action) {
    return this.permissionResolverService.setModuleName("Ticketing").can(action);
  }

}
