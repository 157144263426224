import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreInventoryService} from '../core-inventory.service';
import {Consignment} from '@appModels/core-inventory/consignment/consignment';
import {ConsignmentProduct} from '@appModels/core-inventory/consignment-product/consignment-product';
import * as moment from 'moment';
import {ConsignmentProductError} from "@appStore/actions/core-inventory/consignment-product/consignment-product.action";


@Injectable({providedIn: 'root'})
export class ConsignmentService extends CoreInventoryService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
  }

  /** GET consignment by id. Will 404 if id not found */
  getConsignmentByStockingPointId(id: number): Observable<any> {
    const url = `${this.base_url}stockingpoint/${id}/consignments`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched consignment id=${id}`)),
      catchError(this.handleError(`consignment id=${id}`))
    );
  }

  /** GET consignment by id. Will 404 if id not found */
  getConsignmentByTenantId(filters: any): Observable<any> {
    const id = localStorage.getItem('tenant');
    const url = `${this.base_url}consignment/tenant/${id}`;


    let params = new HttpParams();
    if (filters !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(filters).forEach(val => {
        if (filters[val] !== null && filters[val] !== undefined && filters[val] !== '') {
          params = params.append(val, filters[val]);
        }
      });
    }

    if (filters !== '' && filters.selectedCreatedAtFromTo) {
      console.log("invoke");
      // params['dateFrom'] = moment(filters.selectedCreatedAtFromTo[0]).format('YYYY-MM-DD');
      // params['dateTo'] = moment(filters.selectedCreatedAtFromTo[1]).format('YYYY-MM-DD');

      let begin = filters.selectedCreatedAtFromTo[0];
      let end = filters.selectedCreatedAtFromTo[1];


      console.log(filters);
      params = params.append('dateFrom',begin.getFullYear() + "-" + (begin.getMonth() + 1) + "-" + begin.getDate());
      params = params.append('dateTo', end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate());
    }


    return this.http.get(url, {headers: this.headers, params: params}).pipe(
      tap(_ => this.log(`fetched consignment id=${id}`)),
      catchError(this.handleError(`consignment id=${id}`))
    );
  }

  /** GET consignment by id. Will 404 if id not found */
  getConsignmentById(id: number): Observable<Consignment> {
    const url = `${this.base_url}consignment/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap((consignment: Consignment) => this.log(`fetched consignment w/ id=${consignment.id}`)),
      catchError(this.handleError<Consignment>('fetched Consignment'))
    );
  }

  getMappedRepairCentre(repairCentreId: number): Observable<any> {
    const url = `${this.base_url}consignment/mappedrepaircentre/${repairCentreId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap((_) => this.log(`fetched Mapped Repair Centre`)),
      catchError(this.handleError<any>('getMappedRepairCentre'))
    );
  }

  /** GET consignment by id. Will 404 if id not found */
  getNotClosedConsignmentsByStockingPointFromId(stockingPointFromId: number): Observable<Consignment[]> {
    const url = `${this.base_url}consignment/notclosed/from/${stockingPointFromId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap((consignment: Consignment[]) => this.log(`fetched consignment w/ stocking point from id=${stockingPointFromId}`)),
      catchError(this.handleError<Consignment[]>('fetched Consignment'))
    );
  }

  /** POST: add a new consignment to the server */
  addConsignment(consignment: Consignment): Observable<Consignment> {
    const url = `${this.base_url}consignment`;
    return this.http.post<Consignment>(url, consignment, {headers: this.headers}).pipe(
      tap((consignment: Consignment) => this.log(`added consignment w/ id=${consignment.id}`)),
      catchError(this.handleError<Consignment>('addConsignment'))
    );
  }

  cancelConsignment(consignmentId: number): Observable<any> {
    const url = `${this.base_url}consignment/cancel/` + consignmentId;
    return this.http.post(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`cancelled consignment`)),
      catchError(this.handleError<any>('canceled consignment'))
    );
  }

  /** POST: add a new consignment to the server */
  addConsignmentWithProduct(consignment: any, productId: number): Observable<Consignment> {
    consignment.productId = productId;
    const url = `${this.base_url}consignmentwithproduct`;
    return this.http.post<Consignment>(url, consignment, {headers: this.headers}).pipe(
      tap((consignment1: Consignment) => this.log(`added consignment w/ id=${consignment.id}`)),
      catchError(this.handleError<Consignment>('addConsignment'))
    );
  }

  /** PATCH: Add inventory product quantities to consignment via global product id */
 addProductConsignment(id, data): Observable<any> {
    const url = `${this.base_url}` + 'consignment/' + id + '/addInventoryProductQuantities';
    return this.http.patch(url, data, {headers: this.headers}).pipe(
      tap((_: any) => this.log(`addInventoryProductQuantities`)),
      catchError(this.handleError<any>('addInventoryProductQuantities'))
    );
  }


  /** PATCH: add a stocking point from to a consignment */
  patchStockingPointFromConsignment(consignment: Consignment): Observable<Consignment> {
    const url = `${this.base_url}` + 'consignment/' + consignment.id + '/stockingpointfrom/' + consignment.parentStockingPointFromId;
    return this.http.patch(url, '', {headers: this.headers}).pipe(
      tap((consignment: Consignment) => this.log(`added  stocking point from w/id=${consignment.parentStockingPointFromId}`)),
      catchError(this.handleError<Consignment>('addConsignment'))
    );
  }


  /** PATCH: add a stocking point to to a consignment */
  patchStockingPointToConsignment(consignment: Consignment): Observable<Consignment> {
    const url = `${this.base_url}` + 'consignment/' + consignment.id + '/stockingpointto/' + consignment.parentStockingPointToId;
    return this.http.patch(url, '', {headers: this.headers}).pipe(
      tap((consignment: Consignment) => this.log(`added  stocking point to w/id=${consignment.parentStockingPointToId}`)),
      catchError(this.handleError<Consignment>('add stocking point to'))
    );
  }

  /** PATCH inventory product to consignment */
  addInventoryProductToConsignment(consignmentId: number, inventoryProductId: number): Observable<ConsignmentProduct> {
    const url = `${this.base_url}consignment/${consignmentId}/inventoryProduct/${inventoryProductId}`;
    return this.http.patch(url, {headers: this.headers}).pipe(
      tap((consignmentProduct: ConsignmentProduct) => this.log(`added consignment product w/ id=${consignmentProduct.id}`)),
      catchError(this.handleError<ConsignmentProduct>('added Consignment product'))
    );
  }

  /** DELETE inventory product FROM consignment */
  removeInventoryProductToConsignment(consignmentId: number, inventoryProductId: number): Observable<any> {
    const url = `${this.base_url}consignment/${consignmentId}/inventoryProduct/${inventoryProductId}`;
    return this.http.delete(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted consignment product from consignment`)),
      catchError(this.handleError<any>('deleted consignment product'))
    );
  }

  /** PUT: consignment on the server */
  updateConsignment(consignment: Consignment): Observable<Consignment> {
    const url = `${this.base_url}consignment/${consignment.id}`;
    return this.http.put(url, consignment, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated Consignment id=${consignment.id}`)),
      catchError(this.handleError<any>('update Consignment'))
    );
  }

  patchShippingCompanyConsignment(consignment: Consignment): Observable<Consignment> {
    const url = `${this.base_url}` + 'consignment/' + consignment.id + '/shippingcompany/' + consignment.parentShippingCompanyId;
    return this.http.patch(url, '', {headers: this.headers}).pipe(
      tap((consignment: Consignment) => this.log(`added shipping company w/id=${consignment.parentShippingCompanyId}`)),
      catchError(this.handleError<Consignment>('add shipping company to'))
    );
  }

  upLoadConsignmentProduct(consignmentId: number, consignmentProducts): Observable<{}> {
    this.setHeader(true);
    return this.http.post(this.base_url + 'consignment/' + consignmentId + '/uploadInventoryProductExcel', consignmentProducts, {headers: this.headers, responseType: 'text'}).pipe(
      tap((user) => this.log(`uploaded consignment products `)),
      // catchError(error => of(new ConsignmentProductError(error)))
    );
  }

  upLoadConsignmentPictures(consignmentId: number, consignmentPictures): Observable<{}> {
    this.setHeader(true);
    return this.http.post<{}>(this.base_url + 'consignment/' + consignmentId + '/consignmentCreate/imageUpload', consignmentPictures, {headers: this.headers}).pipe(
      tap((user) => this.log(`uploaded consignment pictures `)),
      catchError(this.handleError<{}>('uploaded consignment pictures '))
    );
  }

  receiveConsignmentPictures(consignmentId: number, consignmentPictures): Observable<{}> {
    this.setHeader(true);
    return this.http.post<{}>(this.base_url + 'consignment/' + consignmentId + '/consignmentReceive/imageUpload', consignmentPictures, {headers: this.headers}).pipe(
      tap((user) => this.log(`uploaded consignment pictures `)),
      catchError(this.handleError<{}>('uploaded consignment pictures '))
    );
  }

  addTransitConsignmentPictures(consignmentId: number, transitConsignmentPictures): Observable<{}> {
    this.setHeader(true);
    return this.http.post<{}>(this.base_url + 'consignment/' + consignmentId + '/consignmentTransit/imageUpload', transitConsignmentPictures, {headers: this.headers}).pipe(
      tap((user) => this.log(`transitConsignmentPictures`)),
      catchError(this.handleError<{}>('transitConsignmentPictures'))
    );
  }
   /** GET consignment by id. Will 404 if id not found */
   getInventoryLevel(id: number): Observable<any> {
    const url = `${this.base_url}consignment/${id}/inventoryLevelsAtDestination`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap((consignment: any) => this.log(`fetched consignment inventory w/ id=${consignment.id}`)),
      catchError(this.handleError<any>('fetched Consignment inventory'))
    );
  }

  getConsignmentImages(consignmentId: number, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}consignment/${consignmentId}/images`;
    return this.http.get<any>(url, {
      params,
      headers: this.headers
    }).pipe(
      tap(_ => this.log(`fetched consignment products images`)),
      catchError(this.handleError('fetched consignment products images', []))
    );
  }

  getConsignmentImageDetails(imageId: number): Observable<any> {
    const url = `${this.base_url}consignment/image/${imageId}`;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched consignment products image detail`)),
      catchError(this.handleError<any>('fetched consignment products image detail'))
    );
  }

  addQuantityGlobalProductVersionToConsignment(consignmentId: number, globalProductVersionId: number, quantity: number, wordOrderId?): Observable<any> {
    return this.http.post<{}>(`${this.base_url}consignment/${consignmentId}/globalproductversion/${globalProductVersionId}/quantity/${quantity}/${wordOrderId ? `?wordOrderId=${wordOrderId}` : ``}`, '', {headers: this.headers}).pipe(
      tap(_ => this.log(`added consignment products`)),
      catchError(this.handleError<any>('added consignment products'))
    );
  }


  getConsignmentProductsGroupByProductName(consignmentId: number): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    const url = `${this.base_url}consignment/${consignmentId}/tenant/${tenantId}/getconsignmentproductsgroupbyproductname`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched consignment products grouped by name and type`)),
      catchError(this.handleError<any>('fetched consignment products grouped by name and type'))
    );
  }

  getConsignmentStatuses(): Observable<any> {
    const url = `${this.base_url}consignment/statuses`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched consignment statuses}`)),
      catchError(this.handleError(`consignment statuses}`))
    );
  }

  getConsignmentContentTypes(): Observable<any> {
    const url = `${this.base_url}consignment/contenttypes`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched consignment content types}`)),
      catchError(this.handleError(`getConsignmentContentTypes`))
    );
  }

  getConsignmentOrders(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(`${this.base_url}consignmentorder`, {
      params,
      headers: this.headers
    }).pipe(
      tap(_ => this.log(`fetched consignment orders`)),
      catchError(this.handleError('getConsignmentOrders', []))
    );
  }

  getConsignmentOrderById(id: number): Observable<any> {
    const url = `${this.base_url}consignmentorder/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched consignment order by id}`)),
      catchError(this.handleError(`getConsignmentOrderById`))
    );
  }

  prepareConsignmentOrder(supplierStockingPointId: number): Observable<any> {
    const url = `${this.base_url}consignmentorder/prepare/supplier/${supplierStockingPointId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`prepare consignment order}`)),
      catchError(this.handleError(`prepareConsignmentOrder`))
    );
  }

  saveConsignmentOrder(buyerId: number, data): Observable<any> {
    const url = `${this.base_url}consignmentorder/create/${buyerId}`;
    return this.http.post(url, data, {headers: this.headers}).pipe(
      tap(_ => this.log(`save consignment order}`)),
      catchError(this.handleError(`saveConsignmentOrder`))
    );
  }

  getConsignmentReturnReason(){
    const url = `${this.base_url}consignment/return/reasons`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`ConsignmentReturnReason`)),
      catchError(this.handleError(`getConsignmentReturnReason`))
    );
  }
}
