import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {TokenStorage} from './token-storage.service';
import {Authentication} from '@appModels/core-auth/auth/auth';
import {UserService} from '@appServices/core-auth/user/user.service';
import {BaseService} from '@appServices/base.service';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';
import {Router} from '@angular/router';
import { StockingPointService } from '../../core-inventory/stocking-point/stocking-point.service';

interface AccessData {
  authorization: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {

  constructor(private http: HttpClient,
              private tokenStorage: TokenStorage,
              private userService: UserService,
              private tenantService: TenantService,
              private stockingPointService: StockingPointService,
              private router: Router
  ) {
    super(http, 'coreauthenticationservice', 'login');
  }

  /**
   *
   *
   * @param {Authentication} data
   * @returns {Observable<string>}
   * @memberof AuthService
   */
  login(data: Authentication): Observable<any> {
    return this.http.post(this.base_url, data, {
      headers: {'Content-Type': 'application/json'}
    }).pipe(
      tap(resp => this.saveAccessData(resp, data)),
      catchError((err) => throwError(err))
    );
  }

  saveLogInIp(data: Authentication): Observable<any> {
    return this.http.post(this.base_url + '/saveLogInIP', data, {
      headers: {'Content-Type': 'application/json'}
    }).pipe(
      tap(resp => this.saveAccessData(resp, data)),
      catchError((err) => throwError(err))
    );
  }

  saveTenantPref() {
    return this.tenantService.getTenantPreference(+localStorage.getItem('tenant'))
      .pipe(tap(data => {
        localStorage.setItem('countryCodeNumeric', data?.countryCodeNumeric);
        localStorage.setItem('country', data?.countryCodeAlpha2CCA2);
        localStorage.setItem('timezone', data?.timezoneUTCOffset);
        localStorage.setItem('currency', data?.currency);
        localStorage.setItem('countryId', data?.countryId);
        localStorage.setItem('tenantPreference', JSON.stringify(data));
        localStorage.removeItem('JwtErrorShown');
      }));
  }

  // replace with user ID
  getStockingPointUser(userId){
    this.stockingPointService.getStockingPointUser(userId).subscribe(
      data => {
        localStorage.setItem('stockingPointUser',  JSON.stringify(data))
      }
    )
  }


  /**
   * Save access data in the storage
   *
   * @private
   * @param {AccessData} data
   */
  private saveAccessData(res, data) {
    if(res){
      this.tokenStorage.setAccessToken(JSON.stringify({authorization: res?.jwttoken}));
      localStorage.setItem('user', JSON.stringify(res));
      localStorage.removeItem('JwtErrorShown');
      this.getStockingPointUser(res.id)
    }
    if (res && res.tenants_?.length > 0) {

      const filtered = res.tenants_.filter(o => o.status === 1 && o.default === true);

      if (filtered.length > 0) {
        localStorage.setItem('tenant', filtered[0].id);
        localStorage.setItem('tenantName', filtered[0].name);
        localStorage.setItem('tenants', JSON.stringify(res.tenants_));

      } else {
        localStorage.setItem('nextUrl', '/activation');
        localStorage.setItem('tenant', res.tenants_[0].id);
        localStorage.setItem('tenantName', res.tenants_[0].name);
        localStorage.setItem('tenants', JSON.stringify(res.tenants_));
      }

      this.saveTenantPref().subscribe();



    }

    // else if(res.tenants_[0].status === 0){
    //   this.router.navigateByUrl(`/activate`);
    // }

  }

}
