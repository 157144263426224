
<div class="flex flex-row justify-between my-8 mx-4">
  <div class="flex flex-row justify-start  gap-3" >
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      Disposition Map
    </div>
  </div>


  <button class="btn btn-dlight flex-grow-0 gap-3" routerLink="/ticketing/newDispositionsMap" >
    New Map
    <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 20 20" width="20" height="20"><path d="M16.2 10.833q-.3 0-.567-.1l-1.1-.333a1.667 1.667 0 0 0-2.067.833l-.199.367a10 10 0 0 1-2.233-1.667A10 10 0 0 1 8.367 7.7l.366-.233A1.667 1.667 0 0 0 9.567 5.4l-.334-1.1-.1-.567a2.5 2.5 0 0 0-2.5-2.066h-2.5a2.5 2.5 0 0 0-2.5 2.833A15.83 15.83 0 0 0 15.4 18.233h.333A3.33 3.33 0 0 0 17.4 17.6a2.67 2.67 0 0 0 .833-1.867v-2.5a2.5 2.5 0 0 0-2.066-2.4m.433 5a1 1 0 0 1-.3.633 1 1 0 0 1-.7.201 14.17 14.17 0 0 1-12.2-12.3 1 1 0 0 1 .2-.7 1 1 0 0 1 .633-.3h2.5a.83.83 0 0 1 .833.667l.133.667.4 1.3-1.167.533a.833.833 0 0 0-.4 1.1 12 12 0 0 0 5.833 5.833 1 1 0 0 0 .633 0 1 1 0 0 0 .469-.434l.5-1.166 1.333.4.667.133a.83.83 0 0 1 .667.833Z"/></svg>
  </button>

</div>

<div class="bg-white ticket-table mt-10" style="border-radius:20px">
  <div class="grid grid-cols-4 py-3 ticket-row">
    <div class="col-span-1 my-auto pl-8">
      NAME
    </div>
    <div class="col-span-1 my-auto">
      REQUEST TYPE
    </div>
    <div class="col-span-1 my-auto">
      NUMBER OF DISPOSITIONS
    </div>
    <div class="col-span-1 my-auto">
      ACTIONS
    </div>
  </div>

  <div class="mt-5 mb-5" *ngIf="loadingTable">
    <div class="flex flex-row justify-center w-full  gap-4">
      <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
    </div>
  </div>
  <ng-container *ngIf="!loadingTable">
    <div  class="grid grid-cols-4 py-3 ticket-row cursor-pointer" *ngFor="let map of maps"  >
      <div class="col-span-1 my-auto pl-8 text-black">
        {{map.name}}
      </div>

      <div class="col-span-1 my-auto  text-black">
        {{map.requestType}}
      </div>

      <div class="col-span-1 my-auto text-black-50">
        {{map.numberOfDispositions}}
      </div>

      <div class="col-span-1">
        <button  [routerLink]="'/ticketing/dispositionsmap/' + map.id" type="button" class="btn btn-sm rounded-full btn-outline-dlight "><svg _ngcontent-ydi-c23="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 ltr:mr-1.5 rtl:ml-1.5 ng-tns-c23-15"><circle _ngcontent-ydi-c23="" cx="12" cy="12" r="3" class="ng-tns-c23-15"></circle><path _ngcontent-ydi-c23="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" class="ng-tns-c23-15"></path></svg>&nbsp;&nbsp;Edit </button>
      </div>


    </div>
  </ng-container>


</div>

