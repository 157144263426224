import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {debounce} from 'lodash';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {CurrentUserDetails} from "@appModels/ticketing/current.user.details";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {ProjectService} from "@appServices/core-ticketing/project-service";
import {BaseCheckboxPojo} from "@appModels/ticketing/base.checkbox.pojo";
import {TeamDetails} from "@appModels/ticketing/team.details";
import {BasePojo} from "@appModels/ticketing/base-pojo";

@Component({
  moduleId: module.id,
  templateUrl: './create-team-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./create-team-component.css', '../../../tailwind.css']
})
export class CreateTeamComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private teamsService: TeamsService,
    private projectService: ProjectService,
    private router: Router
  ) {
    this.searchUsers = debounce(this.searchUsers, 500)


    this.projectService.getProjectsForTeamCreation(true).subscribe(x => {
      this.newTeam.projects = x;

    })

    this.teamsService.getRepairCentresWithoutTeam().subscribe(x => {
      this.repairCentres = x;
    })
  }

  mainLoading = false;

  userSearch = null;
  loading = false;
  userSearchResults : CurrentUserDetails[] = [];
  repairCentres : BasePojo[] = [];

  newTeam : TeamDetails = {
    name:null,
    users: [],
    projects : [],
    colorCode: "#ff4613"
  }

  projects :BaseCheckboxPojo[] =  [];

  searchUsers() {
    if (this.userSearch == null || this.userSearch.length == 0) {
      this.userSearchResults = [];
    }
    if (this.userSearch == null || this.userSearch == undefined || this.userSearch.length < 3) {
      return;
    }

    this.userSearchResults = [];

    this.loading = true;
    this.teamsService.queryUsers(this.userSearch, true).subscribe( x => {
      this.userSearchResults = x;
      this.loading = false;
    })
  }

  addUser(user : CurrentUserDetails) {
    //if already added
    for(let i = 0 ; i< this.newTeam.users.length ; i++) {
      if (this.newTeam.users[i].id == user.id){
        return;
      }
    }

    this.newTeam.users.push(user);
    this.userSearchResults = this.userSearchResults.filter(x =>
      x.id != user.id
    )
  }

  removeUser(user: CurrentUserDetails) {
    this.newTeam.users = this.newTeam.users.filter(x =>
      x.id != user.id
    )
  }

  createTeam(){

    if (this.newTeam.name == undefined || this.newTeam.name == null || this.newTeam.name.length < 2) {
      this.toastrService.error("Please enter a longer name");
      return;
    }

    if (this.newTeam.users == null || this.newTeam.users == undefined || this.newTeam.users.length == 0) {
      this.toastrService.error("Please add at least one user");
      return;
    }

    this.mainLoading = true;
    this.newTeam.tenantId = +localStorage.getItem("tenant");

    this.teamsService.createTeam(this.newTeam).subscribe(x=> {
      this.toastrService.success("Team Created Successfully");
      this.router.navigateByUrl(`/ticketing/teams`);
    })

  }




}
