<div class="cuppa-dropdown" (clickOutside)="closeDropdownOnClickOut()" #cuppaDropdown>
    <div class="selected-list" #selectedList>
        <div class="c-btn" (click)="toggleDropdown($event)" [ngClass]="{'disabled': settings.disabled}" [attr.tabindex]="0">

            <span *ngIf="selectedItems?.length == 0">{{settings.text}}</span>
            <span *ngIf="settings.singleSelection && !badgeTempl">
                <span *ngFor="let item of selectedItems;trackBy: trackByFn.bind(this);let k = index">
                    {{item[settings.labelKey]}}
                </span>
            </span>
            <span class="c-list" *ngIf="selectedItems?.length > 0 && settings.singleSelection && badgeTempl ">
                <div class="c-token" *ngFor="let item of selectedItems;trackBy: trackByFn.bind(this);let k = index">
                    <span *ngIf="!badgeTempl" class="c-label">{{item[settings.labelKey]}}</span>

                    <span *ngIf="badgeTempl" class="c-label">
                        <c-templateRenderer [data]="badgeTempl" [item]="item"></c-templateRenderer>
                    </span>
                    <span class="c-remove" (click)="onItemClick(item,k,$event);$event.stopPropagation()">
                        <c-icon [name]="'remove'"></c-icon>
                    </span>
                </div>
            </span>
            <div class="c-list" *ngIf="selectedItems?.length > 0 && !settings.singleSelection">
                <div class="c-token" *ngFor="let item of selectedItems;trackBy: trackByFn.bind(this);let k = index" [hidden]="k > settings.badgeShowLimit-1">
                    <span *ngIf="!badgeTempl" class="c-label">{{item[settings.labelKey]}}</span>
                    <span *ngIf="badgeTempl" class="c-label">
                        <c-templateRenderer [data]="badgeTempl" [item]="item"></c-templateRenderer>
                    </span>
                    <span class="c-remove" (click)="onItemClick(item,k,$event);$event.stopPropagation()">
                        <c-icon [name]="'remove'"></c-icon>
                    </span>
                </div>
            </div>
            <span class="countplaceholder" *ngIf="selectedItems?.length > settings.badgeShowLimit">+{{selectedItems?.length - settings.badgeShowLimit }}</span>
            <span class="c-remove clear-all" *ngIf="settings.clearAll && selectedItems?.length > 0 && !settings.disabled" (click)="clearSelection($event);$event.stopPropagation()">
                <c-icon [name]="'remove'"></c-icon>
            </span>
            <span *ngIf="!isActive" class="c-angle-down">
                <c-icon [name]="'angle-down'"></c-icon>
            </span>
            <span *ngIf="isActive" class="c-angle-up">
                <c-icon [name]="'angle-up'"></c-icon>

            </span>
        </div>
    </div>
    <div #dropdownList class="dropdown-list animated fadeIn" 
    [ngClass]="{'tagToBody': settings.tagToBody}"
    [style.width.px]="dropDownWidth" 
    [style.top]="dropDownTop" 
    [style.bottom]="dropDownBottom"
    [style.left.px]="dropDownLeft" 
        [hidden]="!isActive">
        <div [ngClass]="{'arrow-up': settings.position == 'bottom', 'arrow-down': settings.position == 'top'}" class="arrow-2"></div>
        <div [ngClass]="{'arrow-up': settings.position == 'bottom', 'arrow-down': settings.position == 'top'}"></div>
        <div class="list-area" [ngClass]="{'single-select-mode': settings.singleSelection }">
            <div class="pure-checkbox select-all" *ngIf="settings.enableCheckAll && !settings.singleSelection && !settings.limitSelection && data?.length > 0 && !isDisabledItemPresent"
                >
                <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelectAll" [disabled]="settings.limitSelection == selectedItems?.length"
                [id]="id" (change)="toggleSelectAll($event)"/>
                <label [for]="id">
                    <span [hidden]="isSelectAll">{{settings.selectAllText}}</span>
                    <span [hidden]="!isSelectAll">{{settings.unSelectAllText}}</span>
                </label>
            </div>
            <img class="loading-icon" *ngIf="loading" src="assets/img/loading.gif" />
            <div class="list-filter" *ngIf="settings.enableSearchFilter">
                <span class="c-search" id="searchIcon">
                    <c-icon [name]="'search'"></c-icon>
                </span>
                <span *ngIf="!settings.lazyLoading" [hidden]="filter == undefined || filter?.length == 0" class="c-clear" (click)="clearSearch()">
                    <c-icon [name]="'clear'"></c-icon>
                </span>
                <span *ngIf="settings.lazyLoading" [hidden]="filter == undefined || filter?.length == 0" class="c-clear" (click)="resetInfiniteSearch()">
                    <c-icon [name]="'clear'"></c-icon>
                </span>

                <input class="c-input" *ngIf="settings.groupBy && !settings.lazyLoading && !searchTempl" #searchInput type="text" [placeholder]="settings.searchPlaceholderText"
                    [(ngModel)]="filter" (keyup)="filterGroupedList()" aria-labelledby="searchIcon">
                <input class="c-input" *ngIf="!settings.groupBy && !settings.lazyLoading && !searchTempl" #searchInput type="text" [placeholder]="settings.searchPlaceholderText"
                    [(ngModel)]="filter" (keyup)="filteritems($event)" aria-labelledby="searchIcon">
                <input class="c-input" *ngIf="settings.lazyLoading && !searchTempl" #searchInput type="text" [placeholder]="settings.searchPlaceholderText"
                    [(ngModel)]="filter" (keyup)="onKeyUp($event)" aria-labelledby="searchIcon">
                <!--            <input class="c-input" *ngIf="!settings.lazyLoading && !searchTempl && settings.groupBy" #searchInput type="text" [placeholder]="settings.searchPlaceholderText"
                [(ngModel)]="filter" (keyup)="filterGroupList($event)">-->
                <c-templateRenderer *ngIf="searchTempl" [data]="searchTempl" [item]="item"></c-templateRenderer>
            </div>
            <div class="filter-select-all" *ngIf="!settings.lazyLoading && settings.enableFilterSelectAll && !isDisabledItemPresent">
                <div class="pure-checkbox select-all" *ngIf="!settings.groupBy && filter?.length > 0 && filterLength > 0  && !settings.singleSelection" (click)="toggleFilterSelectAll()">
                    <input type="checkbox" [checked]="isFilterSelectAll" [disabled]="settings.limitSelection == selectedItems?.length" aria-labelledby="optionName"
                    aria-label="option"/>
                    <label>
                        <span [hidden]="isFilterSelectAll">{{settings.filterSelectAllText}}</span>
                        <span [hidden]="!isFilterSelectAll">{{settings.filterUnSelectAllText}}</span>
                    </label>
                </div>
                <div class="pure-checkbox select-all" *ngIf="settings.groupBy && filter?.length > 0 && groupedData?.length > 0  && !settings.singleSelection" (click)="toggleFilterSelectAll()">
                    <input type="checkbox" [checked]="isFilterSelectAll && filter?.length > 0" [disabled]="settings.limitSelection == selectedItems?.length"
                    aria-labelledby="option"/>
                    <label>
                        <span [hidden]="isFilterSelectAll">{{settings.filterSelectAllText}}</span>
                        <span [hidden]="!isFilterSelectAll">{{settings.filterUnSelectAllText}}</span>
                    </label>
                </div>
            </div>
            <div class="filter-select-all" *ngIf="settings.lazyLoading && settings.enableFilterSelectAll && !isDisabledItemPresent && !settings.singleSelection">
                <div class="pure-checkbox select-all" *ngIf="filter?.length > 0 && infiniteFilterLength > 0" (click)="toggleInfiniteFilterSelectAll()">
                    <input type="checkbox" [checked]="isInfiniteFilterSelectAll" [disabled]="settings.limitSelection == selectedItems?.length"
                    aria-labelledby="option"/>
                    <label>
                        <span [hidden]="isInfiniteFilterSelectAll">{{settings.filterSelectAllText}}</span>
                        <span [hidden]="!isInfiniteFilterSelectAll">{{settings.filterUnSelectAllText}}</span>
                    </label>
                </div>
            </div>
            <div class="filter-select-all" *ngIf="filter?.length">
                <div class="btn-container" *ngIf="settings.addNewItemOnFilter">
                    <button class="c-btn btn-iceblue" (click)="addFilterNewItem()">{{settings.addNewButtonText}}</button>
                </div>
            </div>

            <div *ngIf="!settings.groupBy && !settings.lazyLoading && itemTempl == undefined" [style.maxHeight]="settings.maxHeight+'px'"
                style="overflow: auto;">
                <ul class="lazyContainer">
                    <li *ngFor="let item of data; let i = index;" (click)="onItemClick(item,i,$event)"
                        class="pure-checkbox" [ngClass]="{'selected-item': isSelected(item) == true }">
                        <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                        aria-labelledby="option"/>
                        <label>{{item[settings.labelKey]}}</label>
                    </li>
                </ul>
            </div>
            <!-- lazy loading -->
            <div *ngIf="!settings.groupBy && settings.lazyLoading && itemTempl == undefined" [style.maxHeight]="settings.maxHeight+'px'"
                style="overflow: auto;">
                <ul virtualScroller #scroll [enableUnequalChildrenSizes]="randomSize" [items]="virtualdata" (vsStart)="onScrollEnd($event)"
                    (vsEnd)="onScrollEnd($event)" [ngStyle]="{'height': settings.maxHeight+'px'}" class="lazyContainer">
                    <li *ngFor="let item of scroll.viewPortItems; let i = index;" (click)="onItemClick(item,i,$event)" class="pure-checkbox"
                        [ngClass]="{'selected-item': isSelected(item) == true }">
                        <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                        />
                        <label>{{item[settings.labelKey]}}</label>
                    </li>
                </ul>
            </div>
            <!-- custom template -->
            <div *ngIf="!settings.groupBy && !settings.lazyLoading && itemTempl != undefined" [style.maxHeight]="settings.maxHeight+'px'"
                style="overflow: auto;">
                <ul class="lazyContainer">
                    <li *ngFor="let item of data; let i = index;" (click)="onItemClick(item,i,$event)"
                        class="pure-checkbox" [ngClass]="{'selected-item': isSelected(item) == true }">
                        <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                        />
                        <label></label>
                        <c-templateRenderer [data]="itemTempl" [item]="item"></c-templateRenderer>
                    </li>
                </ul>
            </div>
            <!-- lazy loading and custom template -->
            <div *ngIf="!settings.groupBy && settings.lazyLoading && itemTempl != undefined" [style.maxHeight]="settings.maxHeight+'px'"
                style="overflow: auto;">
                <ul virtualScroller #scroll2 [enableUnequalChildrenSizes]="randomSize" [items]="virtualdata" (vsStart)="onScrollEnd($event)"
                    (vsEnd)="onScrollEnd($event)" class="lazyContainer" [ngStyle]="{'height': settings.maxHeight+'px'}">
                    <li *ngFor="let item of scroll2.viewPortItems; let i = index;" (click)="onItemClick(item,i,$event)" class="pure-checkbox"
                        [ngClass]="{'selected-item': isSelected(item) == true }">
                        <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                        />
                        <label></label>
                        <c-templateRenderer [data]="itemTempl" [item]="item"></c-templateRenderer>
                    </li>
                </ul>
            </div>
            <!-- lazy loading, group By and custom template -->
            <div *ngIf="settings.groupBy && settings.lazyLoading && itemTempl != undefined" [style.maxHeight]="settings.maxHeight+'px'"
                style="overflow: auto;">
                <ul virtualScroller #scroll3 [enableUnequalChildrenSizes]="randomSize" [items]="virtualdata" (vsStart)="onScrollEnd($event)"
                    (vsEnd)="onScrollEnd($event)" [ngStyle]="{'height': settings.maxHeight+'px'}" class="lazyContainer">
                    <span *ngFor="let item of scroll3.viewPortItems; let i = index;">
                        <li (click)="onItemClick(item,i,$event)" *ngIf="!item.grpTitle" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle && !settings.singleSelection}"
                            class="pure-checkbox">
                            <input *ngIf="settings.showCheckbox && !settings.singleSelection" type="checkbox" [checked]="isSelected(item)" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                            />
                            <label></label>
                            <c-templateRenderer [data]="itemTempl" [item]="item"></c-templateRenderer>
                        </li>
                        <li *ngIf="item.grpTitle" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle && !settings.singleSelection}"
                            class="pure-checkbox">
                            <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(item)" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                            />
                            <label></label>
                            <c-templateRenderer [data]="itemTempl" [item]="item"></c-templateRenderer>
                        </li>
                    </span>
                </ul>
            </div>
            <!-- group By and custom template -->
            <div *ngIf="settings.groupBy && !settings.lazyLoading && itemTempl != undefined" [style.maxHeight]="settings.maxHeight+'px'"
                style="overflow: auto;">
                <ul class="lazyContainer">
                    <span *ngFor="let item of groupedData; let i = index;">
                        <li (click)="selectGroup(item)" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle && !settings.singleSelection}"
                            class="pure-checkbox">
                            <input *ngIf="settings.showCheckbox && !settings.singleSelection" type="checkbox" [checked]="item.selected" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                            />
                            <label>{{item[settings.labelKey]}}</label>
                            <ul class="lazyContainer">
                                <span *ngFor="let val of item.list ; let j = index;">
                                    <li (click)="onItemClick(val,j,$event); $event.stopPropagation()" [ngClass]="{'grp-title': val.grpTitle,'grp-item': !val.grpTitle && !settings.singleSelection}"
                                        class="pure-checkbox">
                                        <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(val)" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(val)) || val.disabled"
                                        />
                                        <label></label>
                                        <c-templateRenderer [data]="itemTempl" [item]="val"></c-templateRenderer>
                                    </li>
                                </span>
                            </ul>

                        </li>
                    </span>
                </ul>
            </div>
            <!-- lazy loading, group By -->
            <div *ngIf="settings.groupBy && settings.lazyLoading && itemTempl == undefined" [style.maxHeight]="settings.maxHeight+'px'"
                style="overflow: auto;">
                <virtual-scroller [items]="groupedData" (vsUpdate)="viewPortItems = $event" (vsEnd)="onScrollEnd($event)" [ngStyle]="{'height': settings.maxHeight+'px'}">
                    <ul virtualScroller #scroll4 [enableUnequalChildrenSizes]="randomSize" [items]="virtualdata" (vsStart)="onScrollEnd($event)"
                        (vsEnd)="onScrollEnd($event)" [ngStyle]="{'height': settings.maxHeight+'px'}" class="lazyContainer">
                        <span *ngFor="let item of scroll4.viewPortItems; let i = index;">
                            <li *ngIf="item.grpTitle" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle && !settings.singleSelection, 'selected-item': isSelected(item) == true }"
                                class="pure-checkbox">
                                <input *ngIf="settings.showCheckbox && !item.grpTitle && !settings.singleSelection" type="checkbox" [checked]="isSelected(item)"
                                    [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                                />
                                <label>{{item[settings.labelKey]}}</label>
                            </li>
                            <li (click)="onItemClick(item,i,$event)" *ngIf="!item.grpTitle" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle && !settings.singleSelection, 'selected-item': isSelected(item) == true }"
                                class="pure-checkbox">
                                <input *ngIf="settings.showCheckbox && !item.grpTitle" type="checkbox" [checked]="isSelected(item)" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                                />
                                <label>{{item[settings.labelKey]}}</label>
                            </li>
                        </span>
                    </ul>
                </virtual-scroller>
            </div>
            <!-- group By -->
            <div *ngIf="settings.groupBy && !settings.lazyLoading && itemTempl == undefined" [style.maxHeight]="settings.maxHeight+'px'"
                style="overflow: auto;">
                <ul class="lazyContainer">
                    <span *ngFor="let item of groupedData ; let i = index;">
                        <li (click)="selectGroup(item)" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle && !settings.singleSelection}"
                            class="pure-checkbox">
                            <input *ngIf="settings.showCheckbox && !settings.singleSelection" type="checkbox" [checked]="item.selected" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(item)) || item.disabled"
                            />
                            <label>{{item[settings.labelKey]}}</label>
                            <ul class="lazyContainer">
                                <span *ngFor="let val of item.list ; let j = index;">
                                    <li (click)="onItemClick(val,j,$event); $event.stopPropagation()" [ngClass]="{'selected-item': isSelected(val) == true,'grp-title': val.grpTitle,'grp-item': !val.grpTitle && !settings.singleSelection}"
                                        class="pure-checkbox">
                                        <input *ngIf="settings.showCheckbox" type="checkbox" [checked]="isSelected(val)" [disabled]="(settings.limitSelection == selectedItems?.length && !isSelected(val)) || val.disabled"
                                        />
                                        <label>{{val[settings.labelKey]}}</label>
                                    </li>
                                </span>
                            </ul>
                        </li>
                    </span>
                    <!-- <span *ngFor="let item of groupedData ; let i = index;">
                    <li (click)="onItemClick(item,i,$event)" *ngIf="!item.grpTitle" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}" class="pure-checkbox">
                    <input *ngIf="settings.showCheckbox && !item.grpTitle" type="checkbox" [checked]="isSelected(item)" [disabled]="settings.limitSelection == selectedItems?.length && !isSelected(item)"
                    />
                    <label>{{item[settings.labelKey]}}</label>
                </li>
                <li *ngIf="item.grpTitle && !settings.selectGroup" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}" class="pure-checkbox">
                    <input *ngIf="settings.showCheckbox && settings.selectGroup" type="checkbox" [checked]="isSelected(item)" [disabled]="settings.limitSelection == selectedItems?.length && !isSelected(item)"
                    />
                    <label>{{item[settings.labelKey]}}</label>
                </li>
                 <li  (click)="selectGroup(item)" *ngIf="item.grpTitle && settings.selectGroup" [ngClass]="{'grp-title': item.grpTitle,'grp-item': !item.grpTitle}" class="pure-checkbox">
                    <input *ngIf="settings.showCheckbox && settings.selectGroup" type="checkbox" [checked]="item.selected" [disabled]="settings.limitSelection == selectedItems?.length && !isSelected(item)"
                    />
                    <label>{{item[settings.labelKey]}}</label>
                </li>
                </span> -->
                </ul>
            </div>
            <h5 class="list-message" *ngIf="data?.length == 0">{{settings.noDataLabel}}</h5>
        </div>
    </div>
</div>