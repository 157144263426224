import {NgModule} from '@angular/core';
import {Select2Component} from 'angular-select2-component/src/select2.component';
import {PhoneMaskDirective} from './phone-mask.directive';
import {FlexmonsterPivotComponent} from '@appContainers/shared-component/flexmonster-pivot.component';
import {FlexmonsterPivotTableComponent} from '@appContainers/shared-component/flexmonster-pivot-table.component';
import {FlexmonsterPivotModule} from 'ngx-flexmonster';
import {ModuleReportLayoutComponent} from '@appContainers/shared-component/module-report-layout/module-report-layout.component';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ModuleReportListComponent} from '@appContainers/shared-component/module-report-list/module-report-list.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {GroupBarChartComponent} from '@appContainers/shared-component/group-bar-chart/group-bar-chart.component';
import {PieChartComponent} from '@appContainers/shared-component/pie-chart/pie-chart.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime-ex';
import { TranslateModule } from '@ngx-translate/core';
import {S3ImageComponent} from "@appContainers/shared-component/s3-image.component";
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { InlineLoadingSpinnerComponent } from './inline-loading-spinner/inline-loading-spinner.component';
import {SearchableApiDropdownComponent} from "@appContainers/shared-component/searchable-api-dropdown.component";
import {AngularMultiSelectModule} from "@appContainers/shared-component/angular2-multiselect-dropdown-patch";

@NgModule({
  declarations: [
    Select2Component,
    PhoneMaskDirective,
    FlexmonsterPivotComponent,
    FlexmonsterPivotTableComponent,
    ModuleReportLayoutComponent,
    ModuleReportListComponent,
    GroupBarChartComponent,
    PieChartComponent,
    S3ImageComponent,
    AutocompleteComponent,
    InlineLoadingSpinnerComponent,
    SearchableApiDropdownComponent,
  ],
  imports: [
    FlexmonsterPivotModule,
    FormsModule,
    RouterModule,
    CommonModule,
    NgxDatatableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslateModule,
    AutocompleteLibModule,
    AngularMultiSelectModule,
  ],
  exports: [
    Select2Component,
    PhoneMaskDirective,
    FlexmonsterPivotComponent,
    FlexmonsterPivotTableComponent,
    ModuleReportLayoutComponent,
    ModuleReportListComponent,
    GroupBarChartComponent,
    PieChartComponent,
    S3ImageComponent,
    AutocompleteComponent,
    InlineLoadingSpinnerComponent,
    SearchableApiDropdownComponent,
  ]
})
export class SharedComponentModule {

}
