import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from "rxjs";
import {ToastrService} from 'ngx-toastr';
import {
  AddWorkOrder,
  AddWorkOrderSuccess,
  WorkOrderActionTypes,
  WorkOrderAddAmountToContractSuccess,
  WorkOrderAddNote,
  WorkOrderAddNoteSuccess,
  WorkOrderAddOrRemoveWORepair,
  WorkOrderAddTokensSuccess,
  WorkOrderAssignAction,
  WorkOrderAssignActionSuccess,
  WorkOrderAssignTechnician,
  WorkOrderAssignTechnicianSuccess,
  WorkOrderCancelAction,
  WorkOrderCancelActionSuccess,
  WorkOrderChangeState,
  WorkOrderChangeStateSuccess,
  WorkOrderCloseAction,
  WorkOrderCloseActionSuccess,
  WorkOrderError,
  WorkOrderEscalateAction,
  WorkOrderEscalateSuccess,
  WorkOrderGenerateQuoatation,
  WorkOrderGenerateQuoatationSuccess,
  WorkOrderGetAll,
  WorkOrderGetAllSuccess,
  WorkOrderGetById,
  WorkOrderGetByIdSuccess,
  WorkOrderInvalidateQuoatation,
  WorkOrderInvalidateQuoatationSuccess,
  WorkOrderReceiveSuccess,
  WorkOrderRepairAction,
  WorkOrderRepairSuccess,
  WorkOrderReplace,
  WorkOrderReplaceSuccess,
  WorkOrderSaveTestCases,
  WorkOrderSaveTestCasesSuccess
} from '../../../actions/core-aftersales/work-order/workorder.action';
import {Router} from '@angular/router';
import {WorkOrderService} from '../../../../services/core-aftersales/work-order/work-order.service';

@Injectable()
export class WorkOrderEffects {
  
  addWorkOrder = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderAdd),
    switchMap((action: AddWorkOrder) =>
      this.workOrderService
        .saveWorkOrder(action.payload.workOrder, action.payload.ticket, action.payload.images)
        .pipe(
          map(
            workOrder => {
              if (workOrder !== undefined) {
                this.toastr.success('Work order created successfully!', 'Successful!');
                this.router.navigateByUrl('/warranty-and-service/work_order/' + workOrder.id);
                return new AddWorkOrderSuccess(workOrder);
              }
              this.toastr.error('There was an error creating work order!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  receiveWorkOrder = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderReceive),
    switchMap((action: AddWorkOrder) =>
      this.workOrderService
        .receiveItems(action.payload.items, action.payload.ticket)
        .pipe(
          map(
            (response) => {
              if (response) {
                this.toastr.success('Item received successfully!', 'Successful!');
                this.router.navigateByUrl('/warranty-and-service/ticket/list/' + response.id);
                return new WorkOrderReceiveSuccess();
              } else {
                this.toastr.error('There was an error receiving items in repair centre!', 'Unknown error');
                return new WorkOrderError({type: 500, message: 'Internal error'});
              }
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrder$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderGetById),
    mergeMap((action: WorkOrderGetById) =>
      this.workOrderService
        .getWorkOrder(action.payload)
        .pipe(
          map(wo => new WorkOrderGetByIdSuccess(wo)),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderAssigned$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderAssignTechnician),
    switchMap((action: WorkOrderAssignTechnician) =>
      this.workOrderService
        .assignToTechnician(action.payload.id, action.payload.technicianId)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Work order assigned successfully!', 'Successful!');
                return new WorkOrderAssignTechnicianSuccess(wo);
              }
              this.toastr.error('There was an error assiging work order technician!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderAddOrRemoveWORepair$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderAddOrRemoveWORepair),
    switchMap((action: WorkOrderAddOrRemoveWORepair) =>
      this.workOrderService
        .addOrRemoveWorkOrderRepair(action.payload.id, action.payload.workOrderRepairId, action.payload.add)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Work order repair added successfully!', 'Successful!');
                return new WorkOrderAssignTechnicianSuccess(wo);
              }
              this.toastr.error('There was an error adding work order repair!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));


  
  workOrderStateChange$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderChangeState),
    switchMap((action: WorkOrderChangeState) => {
      return this.workOrderService
        .changeState(action.payload.id, action.payload.stateId)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Work order state change successfully!', 'Successful!');
                return new WorkOrderChangeStateSuccess(wo);
              }
              this.toastr.error('There was an error assiging work order state!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    }
    )
  ));

  
  workOrderCancelled$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderCancel),
    switchMap((action: WorkOrderCancelAction) => {
        return this.workOrderService
          .cancelWorkOrder(action.payload.id, action.payload.stateId, action.payload.cancellingComment, action.payload.cancelledBy)
          .pipe(
            map(wo => {
                if (wo !== undefined) {
                  this.toastr.success('Work order cancelled successfully!', 'Successful!');
                  return new WorkOrderCancelActionSuccess(wo);
                }
                this.toastr.error('There was an error cancelling work order!', 'Unknown error');
                return new WorkOrderError({type: 500, message: 'Internal error'});
              }
            ),
            catchError(error => of(new WorkOrderError(error)))
          );
      }
    )
  ));

  
  workOrderActionAssigned$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderAssignAction),
    switchMap((action: WorkOrderAssignAction) =>
      this.workOrderService
        .assignAction(action.payload.id, action.payload.actionId, action.payload.l2Fault, action.payload.l3Fault, action.payload.l4Fault)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Action assigned successfully!', 'Successful!');
                return new WorkOrderAssignActionSuccess(wo);
              }
              this.toastr.error('There was an error assiging action to work order!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderClosed$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderClose),
    switchMap((action: WorkOrderCloseAction) =>
      this.workOrderService
        .closeAction(action.payload.id, action.payload.remainingDays, action.payload.comment, action.payload.returnToRepairCentre, action.payload.collectionStatus, action.payload.agentId, action.payload.serviceProvider, action.payload.deliveryTerms)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Work Order closed successfully!', 'Successful!');
                return new WorkOrderCloseActionSuccess(wo);
              }
              this.toastr.error('There was an error closing work order!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderEscalated$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderEscalate),
    switchMap((action: WorkOrderEscalateAction) =>
      this.workOrderService
        .escalateAction(action.payload)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Work Order escalated successfully!', 'Successful!');
                return new WorkOrderEscalateSuccess(wo);
              }
              this.toastr.error('There was an error escalating work order!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));
  
  workOrderActionReplaced$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderReplace),
    switchMap((action: WorkOrderReplace) =>
      this.workOrderService
        .replaceAction(action.payload.id, action.payload.productId)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Item replaced successfully!', 'Successful!');
                return new WorkOrderReplaceSuccess(wo);
              }
              this.toastr.error('There was an error replacing this item!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderRepair$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderRepair),
    switchMap((action: WorkOrderRepairAction) =>
      this.workOrderService
        .repairAction(action.payload.id, action.payload.items, action.payload.modules, action.payload.other)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Item repaired successfully!', 'Successful!');
                return new WorkOrderRepairSuccess(wo);
              }
              this.toastr.error('There was an error repairing item!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));


  
  workOrderGenerateQuoatation$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderGenerateQuoatation),
    switchMap((action: WorkOrderGenerateQuoatation) =>
      this.workOrderService
        .generateQuoatation(action.payload.id, action.payload)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Quoatation generated successfully!', 'Successful!');
                return new WorkOrderGetById(action.payload.id)
                // return new WorkOrderGenerateQuoatationSuccess(wo);
              }
              this.toastr.error('There was an error generating quoatation!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderInvalidateQuoatation$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderInvalidateQuoatation),
    switchMap((action: WorkOrderInvalidateQuoatation) =>
      this.workOrderService
        .invalidateQuoatation(action.payload)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Quoatation invalidated successfully!', 'Successful!');
                return new WorkOrderInvalidateQuoatationSuccess(wo);
              }
              this.toastr.error('There was an error invalidating quoatation!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderAddNote$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderAddNote),
    switchMap((action: WorkOrderAddNote) =>
      this.workOrderService
        .addNote(action.payload.id, action.payload.note)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Note added successfully!', 'Successful!');
                return new WorkOrderAddNoteSuccess(wo);
              }
              this.toastr.error('There was an error adding note!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderSaveTestCases$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderSaveTestCases),
    switchMap((action: WorkOrderSaveTestCases) =>
      this.workOrderService
        .saveTestCaseResults(action.payload.id, action.payload.results)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Test case results saved successfully!', 'Successful!');
                return new WorkOrderSaveTestCasesSuccess(wo);
              }
              this.toastr.error('There was an error saving test case results!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderAddAmountToContract = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderAddAmountToContract),
    switchMap((action: WorkOrderAddAmountToContractSuccess) =>
      this.workOrderService
        .saveAmountThatCanBeAddedToContract(action.payload)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Successfully adjusted balance', 'Success');
                return new WorkOrderAddAmountToContractSuccess(wo);
              }
              this.toastr.error('There was an error saving test case results!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrderAddTokens = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderAddTokens),
    switchMap((action: WorkOrderAddTokensSuccess) =>
      this.workOrderService
        .addTokensToWorkOrder(action.payload.id, action.payload.tokenRequestIds)
        .pipe(
          map(wo => {
              if (wo !== undefined) {
                this.toastr.success('Successfully generated token', 'Success');
                return new WorkOrderAddTokensSuccess(wo);
              }
              this.toastr.error('There was an error saving tokens to work order!', 'Unknown error');
              return new WorkOrderError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));

  
  workOrders$ = createEffect(() => this.actions$.pipe(
    ofType(WorkOrderActionTypes.workOrderGetAll),
    mergeMap((action: WorkOrderGetAll) =>
      this.workOrderService
        .getWorkOrders(action.payload)
        .pipe(
          map(wo => new WorkOrderGetAllSuccess(wo)),
          catchError(error => of(new WorkOrderError(error)))
        )
    )
  ));


  constructor(
    private actions$: Actions,
    private workOrderService: WorkOrderService,
    private toastr: ToastrService,
    private router: Router
  ) {
  }
}
