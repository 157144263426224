import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";
import {catchError, tap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class TicketingStatusService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'status');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  createStatus(body:any): Observable<any> {

    const url = `${this.base_url}/create`;
    return this.http.post<any>(url, body,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getByWorkflow(workflowId:number): Observable<any> {

    const url = `${this.base_url}/getByWorkflow/${workflowId}`;
    return this.http.get<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  validateDelete(workflowId:number, statusId:number): Observable<any> {

    const url = `${this.base_url}/validateDelete/workflow/${workflowId}/status/${statusId}`;
    return this.http.post<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getNextAvailableTicketStatuses(id:number): Observable<any> {

    const url = `${this.base_url}/getNextAvailableTicketStatuses/ticket/${id}`;
    return this.http.get<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }


  getStatuses(): Observable<any> {
    const url = `${this.base_url}/getStatuses`;
    return this.http.get<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  updateTicketStatus(id:number, body:any): Observable<any> {

    const url = `${this.base_url}/update/${id}`;
    return this.http.post<any>(url, body,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getTicketStatus(id:number): Observable<any> {

    const url = `${this.base_url}/getTicketStatus/${id}`;
    return this.http.get<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }


}
