
<div class="flex flex-row justify-between my-8 mx-4">
  <div class="flex flex-row  justify-start gap-3" >
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      <span *ngIf="ticketFilter.assignedToMe == null && ticketFilter.raisedByMe == null && ticketFilter.taggedIn == null && ticketFilter.assignedTeamName == null && ticketFilter.assignedUserName == null;">Issues</span>
      <span *ngIf="ticketFilter.raisedByMe">Issues Raised By Me</span>
      <span *ngIf="ticketFilter.assignedToMe">Issues Assigned To Me</span>
      <span *ngIf="ticketFilter.taggedIn">Issues I am Tagged In</span>
      <span *ngIf="ticketFilter.assignedTeamName">{{ticketFilter.assignedTeamName}}</span>
      <span *ngIf="ticketFilter.assignedUserName">{{ticketFilter.assignedUserName}}</span>
    </div>
  </div>


    <button  class="btn btn-dlight flex-grow-0 gap-2" (click)="openNewTicketModal(newTicketModal)">
      New Ticket
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1.6 1.6" xml:space="preserve"><path d="M1.425.45C1.387.488 1.357.5 1.3.5a.194.194 0 0 1-.2-.2c0-.057.012-.087.05-.125L1 .025.025 1l.15.15C.213 1.112.243 1.1.3 1.1c.115 0 .2.085.2.2 0 .057-.012.087-.05.125l.15.15L1.575.6zM.65.375l.1.1m.375.375.1.1M.8.525l.1.1M.975.7l.1.1" fill="none" stroke="white" stroke-width=".1" stroke-miterlimit="10"/></svg>
    </button>

</div>

<!--  table begin -->




  <div class="bg-white ticket-table" style="border-radius:20px" >

    <div class="flex flex-row justify-between p-5">
      <div class="flex flex-row justify-start  gap-3 ">
        <div class="my-auto">
          <svg fill="#949aa0" width="32px" height="32px" viewBox="-0.08 -0.08 0.96 0.96" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" class="jam jam-filter"><path d="m0.083 0.08 0.259 0.324A0.08 0.08 0 0 1 0.36 0.454V0.72l0.08 -0.06v-0.206a0.08 0.08 0 0 1 0.018 -0.05L0.717 0.08zm0 -0.08h0.634a0.08 0.08 0 0 1 0.062 0.13L0.52 0.454v0.206a0.08 0.08 0 0 1 -0.032 0.064l-0.08 0.06A0.08 0.08 0 0 1 0.28 0.72v-0.266L0.021 0.13A0.08 0.08 0 0 1 0.083 0"/></svg>
        </div>
        <div class="my-auto" style="color:#949aa0">Filter</div>
        <div class="my-auto">
          <select class="select_input form-input form-input-focus " placeholder="Priority"  [(ngModel)]="ticketFilter.ticketPriorityId" name="ticketPriorityId">
            <option [value]="priority.id" *ngFor="let priority of priorities"> {{priority.name}} </option>
          </select>
        </div>


        <div class="my-auto" >
          <select class="select_input form-input form-input-focus " placeholder="Request Type" [(ngModel)]="ticketFilter.requestTypeId" name="requestType">

            <option [value]="requestType.id" *ngFor="let requestType of requestTypes"> {{requestType.name}} </option>
          </select>
        </div>


        <div class="my-auto" *ngIf="can('can-filter-tickets-by-assigned-user')">
          <div   class="dropdown" style="position:relative">
            <input  type="text" (click)="showUserDropdown=true"  (keyup)="searchUsers()" [(ngModel)]="userSearch" name="userSearch" class="form-input form-input-focus" >



            <div class="relative">
                <div  *ngIf="showUserDropdown" class="box-shadow-pop" style="border-radius:10px;position:absolute;z-index:999;top:0px;width:300px;min-height:30px;background-color: white">
                  <div class="mt-5 mb-5" *ngIf="loadingUserSearch">
                    <div class="flex flex-row justify-center w-full  gap-4">
                      <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
                    </div>
                  </div>
                  <ng-container *ngIf="!loadingUserSearch">

                     <span (click)="filterUnassignedTickets()" *ngIf="userSearchResults.length == 0"  style="padding:3px;padding-left:8px;padding-right:8px;padding-top:8px;padding-bottom:8px" class=" cursor-pointer ease-in-out duration-300 hover:bg-gray-100 flex p-1 items-center gap-3 justify-start rounded-lg  object-cover text-center  ">
                      <svg width="21" height="21" viewBox="0 0 0.945 0.945" xmlns="http://www.w3.org/2000/svg" class="ng-tns-c2641214630-1"><path d="M.473.446A.184.184 0 1 0 .289.262a.185.185 0 0 0 .184.184m0-.315a.131.131 0 1 1-.131.131.13.13 0 0 1 .131-.131" class="clr-i-outline clr-i-outline-path-1 ng-tns-c2641214630-1"/><path d="M.799.64a.45.45 0 0 0-.655 0A.05.05 0 0 0 .13.676v.138a.053.053 0 0 0 .053.053h.578A.053.053 0 0 0 .815.814V.676A.05.05 0 0 0 .802.64M.761.814H.184V.676a.395.395 0 0 1 .578 0Z" class="clr-i-outline clr-i-outline-path-2 ng-tns-c2641214630-1"/><path d="M0 0h.945v.945H0z" fill="none" class="ng-tns-c2641214630-1"/></svg>
                        <span  >
                        Not Assigned
                      </span>
                      </span>

                  <div (click)="selectUser(user)" *ngFor="let user of userSearchResults" style="padding:3px;padding-right:6px; border-radius:20px"
                       class="cursor-pointer ease-in-out duration-300 hover:bg-gray-100 flex flex-row items-center justify-start gap-3">
                      <span  class="flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                             [ngStyle]="{'background':user.colorCode}">
                        {{user.initials}}
                      </span>
                      <span  >
                            {{user.name}}
                      </span>
                  </div>
                  </ng-container>
                </div>
            </div>


          </div>
        </div>


      </div>

      <div>
        <div class="flex flex-row justify-start">
          <div>
            <input [(ngModel)]="ticketFilter.query" name="ticketFilterQuery" id="addonsLeft" type="text" placeholder="Search Tickets" class="form-input rounded-r-none form-input-focus" />
          </div>
          <button (click)="changeFilter()" type="button" class="btn btn-dlight rounded-l-none gap-3">
            Search
            <svg width="20" height="20" viewBox="0 0 0.375 0.375" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m.363.363-.1-.1m-.1.05a.15.15 0 1 1 0-.3.15.15 0 0 1 0 .3Z" stroke="currentColor" stroke-width=".025"/></svg>
          </button>

        </div>
      </div>
    </div>

    <div class="grid grid-cols-9 py-3 pl-5 pr-3 ticket-row">
      <div class="col-span-1 my-auto">
        TICKET NUMBER
      </div>
      <div class="col-span-1 my-auto">
        CUSTOMER
      </div>
      <div class="col-span-1 my-auto">
        PRIORITY
      </div>
      <div class="col-span-1 my-auto">
        ISSUE TYPE
      </div>
      <div class="col-span-1 my-auto">
        DATE
      </div>
      <div class="col-span-1 my-auto">
        STATUS
      </div>
      <div class="col-span-1 my-auto">
        SLA <span class="text-sm">(Response Time)</span>
      </div>
      <div class="col-span-1 my-auto">
        SLA <span class="text-sm">(Resolution Time)</span>
      </div>
      <div class="col-span-1 my-auto">
        ASSIGNED TO
      </div>

    </div>
    <div class="mt-5 mb-5" *ngIf="loadingTable">
      <div class="flex flex-row justify-center w-full  gap-4">
        <span class="m-auto mb-10 inline-block h-14 w-14 animate-spin rounded-full border-8 border-[#f1f2f3] border-l-dlight align-middle"></span>
      </div>
    </div>
    <ng-container *ngIf="!loadingTable">
    <div  class="grid grid-cols-9 py-3 ticket-row cursor-pointer pl-5 pr-3" *ngFor="let ticket of tickets"  (click)="openViewTicketModal(viewTicketModal, ticket.id)">
      <div class="col-span-1 my-auto  text-black">
        {{ticket.ticketNumber}}
      </div>
      <div class="col-span-1 my-auto text-black">
        {{ticket.customer}}
      </div>
      <div class="col-span-1 my-auto pl-1 justify-center">
        <div *ngIf="ticket.priority && ticket.priority.includes('High')">
          <span class="badge bg-danger rounded-full">High</span>
        </div>
        <div *ngIf="ticket.priority && ticket.priority.includes('Medium')">
          <span class="badge bg-warning rounded-full">Medium</span>
        </div>
        <div *ngIf="ticket.priority && ticket.priority.includes('Low')">
          <span class="badge bg-success rounded-full">Low</span>
        </div>
      </div>
      <div class="col-span-1 my-auto text-black-50">
        {{ticket.issueType}}
      </div>

      <div class="col-span-1 my-auto flex flex-col text-black-50">
        <div>
          {{ moment(ticket.date).format('DD MMMM YYYY') }}
        </div>
        <div class="text-gray-400">
          {{ moment(ticket.date).format('HH:mm') }}
        </div>
      </div>

      <div class="col-span-1 my-auto justify-center">
        <div>
          <span class="badge rounded-full"  [ngStyle]="{'background-color': ticket.ticketStatusColorCode, 'color' : ticket.ticketStatusTextColor}">{{ticket.status}}</span>
        </div>
      </div>

      <div class="col-span-1 my-auto justify-center pl-1 cursor-pointer font-medium text-sm">
        <div *ngIf="ticket.firstResponseSlaGood == true"  class="text-success">
          <span  [tooltip]="'SLA met at ' +  moment(ticket.firstResponseSlaDate).format('DD MMMM YYYY, HH:mm') ">
            {{ticket.firstResponseSlaText}}
          </span>
        </div>
        <div *ngIf="ticket.firstResponseSlaGood == false" class="text-danger">
          <span  [tooltip]="'SLA expired at ' +  moment(ticket.firstResponseSlaEta).format('DD MMMM YYYY, HH:mm') " >
            {{ticket.firstResponseSlaText}}
          </span>
        </div>
        <div *ngIf="ticket.firstResponseSlaGood == null" >
          <span [tooltip]="'SLA open, expires at ' + moment(ticket.firstResponseSlaEta).format('DD MMMM YYYY, HH:mm') ">
            {{ticket.firstResponseSlaText}}
          </span>
        </div>
      </div>

      <div class="col-span-1 my-auto justify-center pl-1 cursor-pointer font-medium text-sm">
        <div *ngIf="ticket.resolutionStatusSlaGood == true"   class="text-success">
          <span [tooltip]="'SLA met at ' +  moment(ticket.resolutionStatusSlaDate).format('DD MMMM YYYY, HH:mm') ">
            {{ticket.resolutionStatusSlaText}}
          </span>
        </div>
        <div *ngIf="ticket.resolutionStatusSlaGood == false"  class="text-danger">
          <span [tooltip]="'SLA expired at ' +  moment(ticket.resolutionStatusSlaEta).format('DD MMMM YYYY, HH:mm') ">
            {{ticket.resolutionStatusSlaText}}
          </span>
        </div>
        <div *ngIf="ticket.resolutionStatusSlaGood == null" >
          <span [tooltip]="'SLA open, expires at ' + moment(ticket.resolutionStatusSlaEta).format('DD MMMM YYYY, HH:mm')">
            {{ticket.resolutionStatusSlaText}}
          </span>
        </div>
      </div>

      <div (click)="$event.stopPropagation()" class="col-span-1 my-auto justify-center">
        <div *ngIf="ticket.assignedName != null " style="padding:3px;padding-right:1px;border:1px dashed #D2D2D2; border-radius:20px" class="cursor-pointer ease-in-out duration-300 hover:bg-gray-100 grid grid-cols-4 items-center justify-start gap-1">
          <span  class=" col-span-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary object-cover text-center text-base text-white"
                [ngStyle]="{'background':ticket.assignedColorCode}">
            {{ticket.assignedInitials}}
            <svg *ngIf="ticket.assignedRepairCentreId != null"  xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 12 12" xml:space="preserve" width="12" height="12"><path d="M10.967.777a.86.86 0 0 0-.608-.245c-.262 0-.526.111-.729.303l-.87.705-.033.031a.82.82 0 0 0-.221.735l-2.029 1.97-.34-.295c.243-.954.213-2.225-.609-3.048C4.925.333 4.155 0 3.357 0a2.8 2.8 0 0 0-.93.158.442.442 0 0 0-.195.705l1.094 1.306-.054.84-.909.104-1.298-1.085a.444.444 0 0 0-.705.195c-.353 1.023-.041 2.125.857 3.022.483.484 1.181.741 2.015.741q.093-.002.183-.006-.046.039-.092.082L.833 8.553a1.78 1.78 0 0 0 0 2.51l.417.417c.335.335.781.519 1.255.519s.92-.184 1.255-.519l2.492-2.493a1.7 1.7 0 0 0 .328-.459l2.213 2.558.021.023c.249.249.621.385 1.048.385.52 0 1.049-.205 1.378-.535.579-.579.763-1.815.149-2.427l-.023-.021-3.222-2.788 1.859-1.915q.076.014.153.014a.8.8 0 0 0 .579-.234l.736-.903c.169-.177.273-.399.298-.628a.87.87 0 0 0-.24-.708zM5.625 8.361l-2.492 2.493a.883.883 0 0 1-1.254 0l-.417-.417a.883.883 0 0 1 0-1.255l2.493-2.493a.87.87 0 0 1 .831-.236l1.101 1.27a.88.88 0 0 1-.258.638m4.991 1.971c-.165.165-.466.274-.753.274-.179 0-.333-.044-.412-.117L4.749 5.057a.45.45 0 0 0-.477-.129 3.3 3.3 0 0 1-1.04.171c-.585 0-1.077-.171-1.389-.481-.28-.28-.675-.777-.733-1.395l.826.692a.45.45 0 0 0 .335.1l1.467-.167a.44.44 0 0 0 .393-.412l.088-1.388a.45.45 0 0 0-.103-.313L3.408.888c.542.015 1.069.253 1.491.675.579.579.567 1.665.309 2.427a.45.45 0 0 0 .129.477l5.434 4.703c.197.216.151.855-.159 1.163m.215-8.259-.696.854-.024-.012-.302-.178-2.334 2.403-.327-.282 2.43-2.356-.178-.301-.012-.025.819-.665.033-.031a.2.2 0 0 1 .117-.062l.539.539a.2.2 0 0 1-.063.116"/></svg>
          </span>
          <span [tooltip]="ticket.assignedName"  class="text-sm col-span-3 assignee-box">
            {{ticket.assignedName}}
          </span>
        </div>

        <span *ngIf="ticket.assignedName == null" style="padding:3px;padding-left:8px;padding-right:8px;border:1px dashed #D2D2D2; border-radius:20px;padding-top:5px;padding-bottom:5px" class=" cursor ease-in-out duration-300 hover:bg-gray-100 flex p-1 items-center gap-1 justify-center rounded-lg  object-cover text-center  ">
          <svg width="21" height="21" viewBox="0 0 0.945 0.945" xmlns="http://www.w3.org/2000/svg" class="ng-tns-c2641214630-1"><path d="M.473.446A.184.184 0 1 0 .289.262a.185.185 0 0 0 .184.184m0-.315a.131.131 0 1 1-.131.131.13.13 0 0 1 .131-.131" class="clr-i-outline clr-i-outline-path-1 ng-tns-c2641214630-1"/><path d="M.799.64a.45.45 0 0 0-.655 0A.05.05 0 0 0 .13.676v.138a.053.053 0 0 0 .053.053h.578A.053.053 0 0 0 .815.814V.676A.05.05 0 0 0 .802.64M.761.814H.184V.676a.395.395 0 0 1 .578 0Z" class="clr-i-outline clr-i-outline-path-2 ng-tns-c2641214630-1"/><path d="M0 0h.945v.945H0z" fill="none" class="ng-tns-c2641214630-1"/></svg>
            <span  >
            Not Assigned
          </span>
        </span>
      </div>



    </div>
    </ng-container>



    <div class="flex flex-row justify-end mr-3 py-3">
      <ul class="inline-flex items-center space-x-1 rtl:space-x-reverse">
        <li *ngIf="ticketFilter.page >=1" ><button (click)="filterPage(ticketFilter.page - 1 )" type="button" class="btn btn-outline-dlight" style="border-radius:10px" >Prev</button></li>
        <li *ngIf="ticketFilter.page >=1"><button (click)="filterPage(ticketFilter.page - 1 )" type="button" class="btn btn-outline-dlight" style="border-radius:10px" >{{ticketFilter.page  }}</button></li>
        <li><button type="button" class="btn btn-dlight" style="border-radius:10px" >{{ticketFilter.page + 1}}</button></li>
        <li *ngIf="!ticketFilter.last"><button (click)="filterPage(ticketFilter.page +1)" type="button" class="btn btn-outline-dlight" style="border-radius:10px" >{{ticketFilter.page + 2 }}</button></li>
        <li *ngIf="!ticketFilter.last"><button (click)="filterPage(ticketFilter.page + 1)" type="button" class="btn btn-outline-dlight" style="border-radius:10px" >Next</button></li>
      </ul>
    </div>




  </div>


<ng-template #newTicketModal>


  <ticket-create (outputCloseEvent)="closeNewTicketModal($event)" ></ticket-create>


</ng-template>


<ng-template #viewTicketModal>

  <ticket-view (outputOpenCustomerEvent)="openCustomerPage($event)" (outputOpenTicketEvent)="openTicketPage($event)" (closeEvent)="closeViewModal()" [setNotesEditable]="false"  [ticketId]="selectedTicketId"></ticket-view>

</ng-template>


