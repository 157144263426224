import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";

@Injectable({ providedIn: 'root' })
export class TicketingService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
    this.headers = this.headers.append('skip-cache', 'true');
  }

  filter(query: any): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        if (query[val] != null && query[val] != undefined && query[val] != 'undefined' && query[val] !== '' && query[val] != 0) {
          params = params.append(val, query[val]);
        }
      });
    }

    const url = `${this.base_url}filter/tenant/` + localStorage.getItem('tenant');
    return this.http.get<any>(url, {params: params,headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }
  getPriorities(): Observable<any> {
    const url = `${this.base_url}priority`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getSources(): Observable<any> {
    const url = `${this.base_url}source`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getCurrentUserDetails(): Observable<any> {
    const url = `${this.base_url}currentUserDetails`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getActivityHistory(id:number): Observable<any> {
    const url = `${this.base_url}activityHistory/ticket/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getComments(id:number): Observable<any> {
    const url = `${this.base_url}comments/ticket/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getActionHistory(id:number): Observable<any> {
    const url = `${this.base_url}actionHistory/ticket/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  getTicket(id:any): Observable<any> {
    const url = `${this.base_url}` + id;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  createTicket(ticket:any): Observable<any> {
    const url = `${this.base_url}tenant/` + localStorage.getItem('tenant');
    return this.http.post<any>(url, ticket,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  saveNotes(ticketId:number, body:any): Observable<any> {
    const url = `${this.base_url}ticket/${ticketId}/saveNotes`;
    return this.http.post<any>(url, body,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  createComment(comment:any, ticketId:number): Observable<any> {
    const url = `${this.base_url}comment/ticket/${ticketId}`;
    return this.http.post<any>(url, comment,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  updateComment(comment:any, commentId:number): Observable<any> {
    const url = `${this.base_url}comment/update/${commentId}`;
    return this.http.post<any>(url, comment,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  changeAssignedUser(ticketId:number, userId:number): Observable<any> {
    const url = `${this.base_url}assign/${ticketId}/user/${userId}`;
    return this.http.post<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  changeAssignedTeam(ticketId:number, teamId:number): Observable<any> {
    const url = `${this.base_url}assign/${ticketId}/team/${teamId}`;
    return this.http.post<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  changeStatus(ticketId:number, statusId:number): Observable<any> {
    const url = `${this.base_url}changeStatus/ticket/${ticketId}/status/${statusId}`;
    return this.http.post<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  changePriority(ticketId:number, priorityId:number): Observable<any> {
    const url = `${this.base_url}changePriority/ticket/${ticketId}/priority/${priorityId}`;
    return this.http.post<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  addUserTag(ticketId:number, userId:number): Observable<any> {
    const url = `${this.base_url}addUserTag/ticket/${ticketId}/user/${userId}`;
    return this.http.post<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  addTeamTag(ticketId:number, teamId:number): Observable<any> {
    const url = `${this.base_url}addTeamTag/ticket/${ticketId}/team/${teamId}`;
    return this.http.post<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  removeUserTag(ticketId:number, userId:number): Observable<any> {
    const url = `${this.base_url}removeUserTag/ticket/${ticketId}/user/${userId}`;
    return this.http.post<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  removeTeamTag(ticketId:number, teamId:number): Observable<any> {
    const url = `${this.base_url}removeTeamTag/ticket/${ticketId}/team/${teamId}`;
    return this.http.post<any>(url,{headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  updateTicketCustomField(ticketId:number, body:any): Observable<any> {
    const url = `${this.base_url}updateTicketCustomField/${ticketId}`;
    return this.http.post<any>(url, body, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }

  closeTicket(ticketId:number): Observable<any> {
    const url = `${this.base_url}closeTicket/${ticketId}`;
    return this.http.post<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }


}
