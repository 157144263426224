<div class="loading" [hidden]="!loadingDetails">
  {{ "Loading" | translate }}&#8230;
</div>
<div class="rows modal-lg" [ngClass]="componentMode === 'READ_ONLY' ? 'centered' : ''">
  <div class="col-md-12">
    <crm-external-pos-and-alerts *ngIf="contractDetails['externalSales'] == true"
      [message]="'External POS Activation'"></crm-external-pos-and-alerts>
    <crm-external-pos-and-alerts *ngIf="contractDetails['kycVerificationIdCardFailed'] == true" [message]="
        'Alert: The National ID number provided on the registration form does not match with the number printed on the national ID card provided'
      "></crm-external-pos-and-alerts>

    <div class="modal-header">
      <div class="rows" style="width: 100%" *ngIf="componentMode === kycComponentModeEnum.READ_ONLY">
        <div class="col-md-11">
          <p>{{'Customer Details' | translate}}</p>
        </div>
        <div class="col-md-1" (click)="closeModal()">
          <p style="font-size: 25px; margin-left: 30px; cursor: pointer">x</p>
        </div>
      </div>

      <crm-kyc-left-modal-header *ngIf="componentMode === kycComponentModeEnum.EDIT"
        [havePermission]="can('can-unassign-user-kyc')" [assignedUser]="assignedUser"
        (unassignUser)="unassignUser(awaitingEscalation)" (unassignVerification)="
          unassignVerification(awaitingEscalation, statusName); closeModal()
        " (closeModal)="closeModal()"></crm-kyc-left-modal-header>
    </div>
    <kyc-top-alerts [kycComponentModeEnum]="kycComponentModeEnum" [componentMode]="componentMode"
      [customerIsASalesAgentWithId]="customerIsASalesAgentWithId" [showMessage]="showMessage"
      [assignedUser]="assignedUser"></kyc-top-alerts>

    <div *ngIf="showComponent && componentMode === kycComponentModeEnum.EDIT">
      <h4 class="m-t-10">{{'Call Script and Score' | translate}}</h4>
      <call-script-container [customer]="awaitingEscalation" [awaitingEscalation]="awaitingEscalation"
        [showComponent]="showComponent" (scoreSaved)="onAllScoreSaved()"></call-script-container>
    </div>

    <div class="modal-body">
      <kyc-spe-detail [kycComponentModeEnum]="kycComponentModeEnum" [componentMode]="componentMode"
        [salesAgent]="salesAgent" [salesAgentPhone]="salesAgentPhone" [statusName]="statusName"
        [formNotValid]="formNotValid" [(sepDetailCheck)]="sepDetailCheck"></kyc-spe-detail>


      <kyc-customer-detail [kycComponentModeEnum]="kycComponentModeEnum" [componentMode]="componentMode"
        [formNotValid]="formNotValid" [statusName]="statusName" [upsellCustomer]="upsellCustomer"
        [awaitingEscalation]="awaitingEscalation" [identificationType]="identificationType" [location]="location"
        [secondaryContactTelephone]="secondaryContactTelephone" [resubmissionCount]="resubmissionCount"
        [rejectionInfo]="rejectionInfo" [(customerDetailCheck)]="customerDetailCheck"></kyc-customer-detail>
      
        @if(checkRoute())
        {
          <app-cco-crb-checks *ngIf="tenantId == 105" [uniqueIdentification]="uniqueIdentification()" [(crbDetailCheck)]="crbDetailCheck" [formNotValid]="formNotValid" [statusName]="statusName"></app-cco-crb-checks>
        }

      <mpesa-statement-summary *ngIf="tenantId == 105 || tenantId == 1001"
        [mpesaStatementSummary]="mpesaStatementSummary" [(mpesaStatementCheck)]="mpesaStatementCheck"
        [componentMode]="componentMode" [statusName]="statusName"></mpesa-statement-summary>

      <kyc-product-detail [kycComponentModeEnum]="kycComponentModeEnum" [componentMode]="componentMode"
        [accessoriesDetails]="accessoriesDetails" [contractDetails]="contractDetails"
        [idVerificationPassed]="idVerificationPassed" [tenantId]="tenantId"
        [primaryProductSmileIdRequired]="primaryProductSmileIdRequired" [formNotValid]="formNotValid"
        [statusName]="statusName" [totalPaid]="totalPaid" [product]="product" [rate]="rate"
        [accountNumber]="accountNumber" [deposit]="deposit"
        [(productDetailCheck)]="productDetailCheck"></kyc-product-detail>

      @if(componentMode === kycComponentModeEnum.EDIT) {
      <lead-smileId [leadSmileIdProduct]="leadSmileIdProduct">
      </lead-smileId>
      }


      <kyc-cook-stove-survey-question [kycComponentModeEnum]="kycComponentModeEnum" [componentMode]="componentMode"
        [preferredTimeCheck]="preferredTimeCheck" [preferredOptionCheck]="preferredOptionCheck"
        [preferredCommunicationOption]="preferredCommunicationOption" [cookstoveDetail]="cookstoveDetail"
        [contractDetails]="contractDetails" [accountNumber]="accountNumber" [deposit]="deposit"
        [accessoriesDetails]="accessoriesDetails" [(preferredOptionTime)]="preferredOptionTime"
        [(otherPreferredOption)]="otherPreferredOption" [(preferredOption)]="preferredOption"
        (changeTime)="changeTime()" (changeCommunication)="changeCommunication()">
      </kyc-cook-stove-survey-question>

      <kyc-image-and-attachments [statusName]="statusName" [attachments]="attachments"
        [attachmentsExists]="attachmentsExists" [image]="image" [imageDetailCheck]="imageDetailCheck"
        [formNotValid]="formNotValid"></kyc-image-and-attachments>
      <kyc-additional-comment [statusName]="statusName" [(additionalComments)]="additionalComments"
        [(additionalKycInfo)]="additionalKycInfo" [(rejectionInfo)]="rejectionInfo" [(blacklistInfo)]="blacklistInfo"
        [(callBackInfo)]="callBackInfo" [resubmissionCheck]="resubmissionCheck" [rejectionCheck]="rejectionCheck"
        [blacklistCheck]="blacklistCheck" [callbackCheck]="callbackCheck" [rejectedReasons]="rejectedReasons"
        [blacklistReasons]="blacklistReasons"></kyc-additional-comment>
    </div>

    <div class="form_group_modal" style="padding-left: 10px; padding-right: 10px;">
      <div class="input m-t-10" *ngIf="
      callbackCheck &&
      (statusName === 'KYC_PENDING' ||
        statusName === 'LEAD' ||
        statusName !== 'KYC_AWAITING_MORE_INFORMATION')
    ">
        <label class="label_title">{{
          "Select Date and Time" | translate
          }}</label>
        <input type="datetime-local" name="callBackInfo" [(ngModel)]="callBackInfo" />
        <div *ngIf="
        callbackCheck && (callBackInfo === '' || callBackInfo === null)
      " class="notification error">
          {{ "Please select call back date" | translate }}
        </div>

      </div>
    </div>
    <left-modal-footer [config]="config" (approveVerification)="approveVerification(awaitingEscalation)"
      (resubmitVerification)="resubmitVerification(awaitingEscalation)"
      (rejectVerification)="rejectVerification(awaitingEscalation)"
      (blacklistVerification)="blacklistVerification(awaitingEscalation)"
      (requestCallBackVerification)="requestCallBackVerification(awaitingEscalation)"
      (cancelClicked)="cancelClicked = true" (closeClicked)="closeModal()" [kycComponentModeEnum]="kycComponentModeEnum"
      [componentMode]="componentMode"></left-modal-footer>
  </div>

  <!-- right modal -->

  <app-right-modal-page *ngIf="!showComponent && componentMode === kycComponentModeEnum.EDIT"
    [customer]="awaitingEscalation" [awaitingEscalation]="awaitingEscalation"
    (scoreSaved)="onAllScoreSaved()"></app-right-modal-page>
</div>