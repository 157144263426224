import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";
import {DispositionsmapService} from "@appServices/core-ticketing/dispositionsmap-service";
import {DispositionsMapListRow} from "@appModels/ticketing/dispositions-map-list-row";

@Component({
  moduleId: module.id,
  templateUrl: './dispositionsmap-table-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./dispositionsmap-table-component.css', '../../../tailwind.css']
})
export class DispositionsmapTableComponent {
  constructor(
    private toastrService: ToastrService,
    private ref: ChangeDetectorRef,
    private dispositionService: DispositionsmapService
  ) {
    this.getMaps();
  }

  loadingTable = true;
  dateFormat = moment().format('YYYY-MM-DD hh:mm:ss');
  moment: any = moment;

  getMaps() {
    this.dispositionService.getListRow(true).subscribe(x=> {
      this.maps = x;
      this.loadingTable = false;
    })
  }

  maps : DispositionsMapListRow[] = [];

}
