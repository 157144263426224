import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { ErrorHandler, Injector, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EffectsModule } from "@ngrx/effects";
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { InMemoryCache } from "@apollo/client/core";
import { APOLLO_OPTIONS, ApolloModule } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";

import { environment } from "environments/environment";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgxMaskModule } from "ngx-mask";
import { AppRoutingModule } from "./app-routing.module";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { NgxDropzoneModule } from "ngx-dropzone";
import { ToastrModule } from "ngx-toastr";

import { CrmModule } from "@appContainers/crm/crm.module";
import { BlankComponent } from "@appLayout/blank/blank.component";
import { MainComponent } from "@appLayout/main/main.component";
import { SidebarComponent } from "@appLayout/sidebar/sidebar.component";
import { AppComponent } from "./app.component";

import { TenantActivationComponent } from "@appContainers/activation/tenant-activation.component";
import { HomeModule } from "@appContainers/home/home.module";
import { FaqModule } from "@appContainers/static-resources/FAQ/faq.module";
import { PrivacyPolicyModule } from "@appContainers/static-resources/privacy-policy/privacy-policy.module";
import { TermsAndConditionsModule } from "@appContainers/static-resources/terms-and-conditions/terms-and-conditions.module";
import { AuthInterceptor } from "@appServices/core-auth/auth/auth.interceptor";
import * as fromStore from "@appStore/index";
import { CustomRouterStateSerializer } from "@appStore/router";

import { SepDashboardModule } from "@appContainers/sep-dashboard/sep-dashboard.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { SentryErrorHandler } from "./sentry-error.handler";
import { CachingInterceptor } from "@appServices/caching-interceptor.service";
import {PlaygroundModule} from "@appContainers/ticketing/components/playground.module";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FeatureToggleModule,
    EditorModule,
    NgxDropzoneModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CrmModule,

    StoreModule.forRoot(fromStore.reducers),
    EffectsModule.forRoot(fromStore.effects),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot({}),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    ApolloModule,
    // HttpLinkModule,
    StoreRouterConnectingModule.forRoot({
      stateKey: "router", // name of reducer key
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 50, connectInZone: true })
      : [],
    NgxDatatableModule,
    HomeModule,
    FaqModule,
    TermsAndConditionsModule,
    PrivacyPolicyModule,
    SepDashboardModule,
    PlaygroundModule
  ],
  declarations: [
    AppComponent,
    BlankComponent,
    SidebarComponent,
    MainComponent,
    TenantActivationComponent,
    // Select2Component
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
    AuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [Injector],
    },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graph_url,
          }),
        };
      },
      deps: [HttpLink],
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
      deps: [Injector],
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
