import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {debounce} from 'lodash';

import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";
import {ToastrService} from "ngx-toastr";
import * as moment from "moment/moment";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrentUserDetails} from "@appModels/ticketing/current.user.details";
import {TeamsService} from "@appServices/core-ticketing/teams-service";
import {TicketTypeService} from "@appServices/core-ticketing/ticket-type.service";
import {BasePojo} from "@appModels/ticketing/base-pojo";
import {PermissionResolver} from "@appServices/permission-resolver/permission-resolver.service";
import {RequestTypeService} from "@appServices/core-ticketing/request-type.service";

@Component({
  moduleId: module.id,
  templateUrl: './issues-component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['./issues-component.css', '../../tailwind.css']
})
export class IssuesComponent {
  constructor(
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private ticketingService: TicketingService,
    private teamsService: TeamsService,
    private ticketTypeService: TicketTypeService,
    private requestTypeService: RequestTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private permissionResolverService: PermissionResolver
  ) {

    this.searchUsers = debounce(this.searchUsers, 500)

    this.getTicketPriorities();
    this.getTicketSources();
    this.getTicketTypes();
    this.getRequestTypes();

    this.route.queryParams.subscribe(params => {
      console.log("param change")
      this.ticketFilter.assignedToMe = params['assignedToMe'];
      this.ticketFilter.teamUuid = params['teamUuid'];
      this.ticketFilter.raisedByMe = params['raisedByMe'];
      this.ticketFilter.taggedIn = params['taggedIn'];
      this.ticketFilter.assignedTeamId = params['assignedTeamId'];
      this.ticketFilter.assignedTeamName = params['assignedTeamName'];
      this.ticketFilter.assignedUserId = null;
      this.ticketFilter.assignedUserName = null;

      this.filterTickets();
    });



  }


  moment:any = moment;
  ticketModalReference: BsModalRef;
  viewTicketModalReference: BsModalRef;
  @ViewChild('newTicketModal') public newTicketModal;
  @ViewChild('viewTicketModal') public viewTicketModal;


  loadingTable = true;
  ticketFilter = {
    page: 0,
    size: 10,
    last: false,
    assignedToMe:null,
    ticketPriorityId:0,
    teamUuid:null,
    ticketTypeId:0,
    requestTypeId:0,
    raisedByMe:null,
    notAssigned:null,
    assignedUserId:null,
    assignedUserName:null,
    assignedTeamId:null,
    assignedTeamName:null,
    taggedIn:null,
    query:null
  }
  priorities : BasePojo[] = [];
  sources: BasePojo[] = [];
  ticketTypes : BasePojo[] = [];
  requestTypes: BasePojo[] = [];


  openNewTicketModal(confirmation: TemplateRef<any>) {
    this.ticketModalReference = this.modalService.show(confirmation, { class: 'custom-window-class' });
  }
  openViewTicketModal(confirmation: TemplateRef<any>, ticketId:number) {
    this.selectedTicketId = ticketId;
    this.viewTicketModalReference = this.modalService.show(confirmation, { class: 'modal-xl' });
  }


  selectedTicketId : number = null;


  tickets =[];



  getTicketPriorities() {
    this.ticketingService.getPriorities().subscribe(x => {
      this.priorities = x;
      this.priorities = [{id:0,name:'Select Priority'}].concat(x);
    })
  }

  getTicketSources() {
    this.ticketingService.getSources().subscribe(x => {
      this.sources = x;
    })
  }

  getTicketTypes() {
    this.ticketTypeService.getTicketTypes(true).subscribe(x => {
      this.ticketTypes = [{id:0,name:'All Ticket Types'}].concat(x);

    })
  }

  getRequestTypes() {
    this.requestTypeService.getRequestTypes(true).subscribe(x => {
      this.requestTypes = [{id:0,name:'All Request Types'}].concat(x);

    })
  }

  changeFilter() {
    this.ticketFilter.page = 0;
    this.ticketFilter.size = 10;
    this.filterTickets()
  }

  filterPage(page:number) {
    this.ticketFilter.page = page;
    this.filterTickets();
  }

  filterTickets() {
    this.loadingTable = true;
    this.ticketingService.filter(this.ticketFilter).subscribe(x=> {
      this.tickets = x.content;
      this.ticketFilter.last = x.last;
      this.loadingTable = false;
    })
  }


  userSearch = "Assigned To";
  userSearchResults : CurrentUserDetails[] = [];
  loadingUserSearch = false;
  showUserDropdown = false;
  searchUsers() {
    this.userSearchResults = []
    if (this.userSearch == null || this.userSearch.length == 0) {
      this.showUserDropdown = false;
      this.ticketFilter.assignedUserId = null;
      this.ticketFilter.assignedTeamId = null;
      this.ticketFilter.assignedTeamName = null;
      this.ticketFilter.assignedUserName = null;
    }
    if (this.userSearch == null || this.userSearch == undefined || this.userSearch.length < 3) {
      return;
    }

    this.showUserDropdown = true;

    this.userSearchResults = [];

    this.loadingUserSearch = true;
    this.teamsService.queryUsersAndTeams(this.userSearch, true).subscribe( x => {
      this.userSearchResults = x;
      this.loadingUserSearch = false;
    })
  }

  selectUser(user: CurrentUserDetails) {
    this.showUserDropdown = false;
    this.ticketFilter.notAssigned = null;
    this.userSearchResults = [];
    this.userSearch = user.name;
    if (user.type == 'user') {
      this.ticketFilter.assignedTeamId = null;
      this.ticketFilter.assignedTeamName = null;
      this.ticketFilter.assignedUserName = user.name;
      this.ticketFilter.assignedUserId = user.id;
    } else if (user.type == 'team') {
      this.ticketFilter.assignedUserId = null;
      this.ticketFilter.assignedUserName = null;
      this.ticketFilter.assignedTeamId = user.id;
      this.ticketFilter.assignedTeamName = user.name;
    }
    this.ticketFilter.assignedToMe = null;
    this.ticketFilter.taggedIn = null;
    this.ticketFilter.raisedByMe = null;
  }



  openTicketPage(id:any) {
    this.viewTicketModalReference.hide();
    this.router.navigateByUrl(`/ticketing/ticket/${id}`)

  }

  openCustomerPage(encryptedId:any) {
    this.viewTicketModalReference.hide();
    this.router.navigateByUrl(`/crm/customers/${encryptedId}`)
  }

  closeViewModal() {
    this.viewTicketModalReference.hide();
  }


  closeNewTicketModal(event:any) {
    this.ticketModalReference.hide();
  }

  filterUnassignedTickets() {
    this.ticketFilter.notAssigned = true ;
    this.showUserDropdown = false;
    this.userSearch = "Not Assigned";
  }

  can(action) {
    return this.permissionResolverService.setModuleName("Ticketing").can(action);
  }

}
